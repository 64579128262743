/* eslint-disable no-eval */

export default `
Масляные фильтры AZUMA прекрасно очищают моторное масло от твердых  органических и неорганических частиц, посторонних примесей и продуктов сгорания, обеспечивая надежную защиту автомобильных  двигателей.

Особая формовка и глубокое гофрирование фильтрующего материала обеспечивает большую площадь фильтрования и исключает проникновение неочищенного масла к деталям двигателя.

Особенности и преимущества:
* Высокая прочность корпусных элементов;
* Двухстороннее антикоррозионное покрытие;
* Устойчивость к высоким температурам.
`
