/* eslint-disable no-eval */

export default `
The AZUMA fan clutch is a simple but well-adjusted mechanism. The bimetallic plate is made of a special elastic alloy. The oil poured into the coupling has a high temperature resistance and stable viscosity. The speed of the impeller is adjusted to the actual engine temperature mode. Only high-quality components are used for long-term operation of the device. Having considered possible breakdowns of the mechanism, we have reduced the possibility of leakage of the working fluid.

Special Features and benefits:

* reduces noise level;
* reduces the load on the generator;
* reduces fuel;
* reduces the loss of engine power.
`
