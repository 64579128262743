/**
 * HAMMER
 * Реализует пан эвенты на hammer.js
 * @param {Node} el - элемент для pan
 * @author Victor Alekseev
 * @version 1.0
 */

import Hammer from 'hammerjs'
import { useStore } from 'vuex'
import { watch } from 'vue'
 
export default function (el, widthFull) {
	/** 
     * GET FULL WIDTH 
     * Находим полную ширину контейнера, учитывая margin & padding & width дочерних элементов
     * @param {Node} el
     * @return {Number}
     * @author Victor Alekseev
     * @version 1.0
     */

	const getFullWidth = (elem) => {
		const childs = elem.children
		let width = 0
		const wrapStyle = getComputedStyle(elem)
		const wrapPadding = +wrapStyle.paddingLeft.replace('px', '') + +wrapStyle.paddingRight.replace('px', '')
		Object.keys(childs).forEach((childEl) => {
			width = width + childs[childEl].clientWidth + +getComputedStyle(childs[childEl]).marginRight.replace('px', '')
		})
		return width + wrapPadding
	}
    
	/**
     * HAMMER EVENT
     * Обработчики событий pan для hammer.js
     * @param {Node} el
     * @author Victor Alekseev
     * @version 1.0
     */

	let eventRight = 0
	let eventFinish = 0
	const hammertime = new Hammer(el)

	const hammerEvent = (elem) => {
		const width = widthFull || getFullWidth(elem)
		const windowWidth = document.documentElement.clientWidth
		if (windowWidth < width) {
			hammertime.set({ enable: true })
			elem.classList.remove('nopan')
			hammertime.on('pan', (e) => {
				if (e.isFinal) {
					eventFinish = eventRight
					if (eventRight > 0) {
						eventRight = 0
						elem.style.transform = 'translate3d(0px, 0, 0)'
						if (e.isFinal) eventFinish = 0
					} else if (eventRight < -(width - windowWidth)) {
						elem.style.transform = `translate3d(${-(width - windowWidth)}px, 0, 0)`
						if (e.isFinal) eventFinish = -(width - windowWidth)
					}
				} else {
					eventRight = eventFinish + e.deltaX
					elem.style.transform = `translate3d(${eventRight}px, 0, 0)`
				}
			})
		} else {
			elem.style.transform = 'translate3d(0, 0, 0)'
			hammertime.set({ enable: false })
			elem.classList.add('nopan')
		}
	}

	/**
     * INIT
     * Запускаем функции и вещаем функции при resize window
     * @author Victor Alekseev
     * @version 1.0
     */

	hammerEvent(el)
	window.addEventListener('resize', () => {
		setTimeout(() => { hammerEvent(el) }, 30)
	})

	const store = useStore()
	watch(() => store.state.lang, () => {
		setTimeout(() => { hammerEvent(el) }, 30)
	})
}
