<template lang="pug">
button.button(
	:class="className"
	:disabled="disabled"
)
	.button-content
		slot
		transition(name="button-loading")
			Preloader(v-if="loading")
</template>

<script>
import { computed } from 'vue'
import Preloader from './IconPreloader.vue'

export default {
	components: {
		Preloader
	},

	props: {
		color: {
			type: String,
			required: true
		},
		loading: {
			type: Boolean,
			required: false
		},
		disabled: {
			type: Boolean,
			required: false
		}
	},

	setup(props) {        
		const className = computed(() => {
			const arr = [
				`button-${props.color}`,
				props.loading ? 'button-loading' : ''
			]
			return arr
		})

		return {
			className
		}
	}
}
</script>

<style lang="postcss" scoped>
@keyframes buttonBeforeShow {
    0% {
        transform: scale(.8) translate3d(0,0,0);
        opacity: 0;
        border-radius: 100%;
    }
    100% {
        transform: scale(1) translate3d(0,0,0);
        opacity: 1;
        border-radius: 4px;
    }
}

@keyframes buttonLoadingShow {
	0% { 
		opacity: 0;
		transform: scale(.6) translate3d(-50%, -50%, 0);
		transform-origin: 0 0;
	}
	100% { 
		opacity: 1;
		transform: scale(1) translate3d(-50%, -50%, 0);
		transform-origin: 0 0;
	}
}

@keyframes buttonLoadingHide {
	0% { 
		opacity: 0;
		transform: scale(.6) translate3d(-50%, -50%, 0);
		transform-origin: 0 0;
	}
	100% { 
		opacity: 1;
		transform: scale(1) translate3d(-50%, -50%, 0);
		transform-origin: 0 0;
	}
}

.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: none;
    font-family: var(--font-condensed);
    font-size: 18px;
    font-weight: 600;
    height: 34px;
    padding-left: 32px;
    padding-right: 32px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 6px;
	transition: all .5s ease-in-out;
	overflow: hidden;
    @media (--viewport-lg) {
        height: 36px;
    }

	&-loading-enter-active { animation: buttonLoadingShow .6s ease-in-out both; }
	&-loading-leave-active { animation: buttonLoadingHide .6s ease-in-out both; }

	&[disabled] {
		cursor: not-allowed;
	}

	&.button-loading {
		width: 34px;
		padding-left: 0;
		padding-right: 0;
		color: transparent;
		border-radius: 30px;
		@media (--viewport-lg) {
			height: 36px;
			border-radius: 30px;
		}

		svg {
			display: block;
			stroke: #fff;
			width: 24px;
			height: 24px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0)
		}
	}

    &-content {
        position: relative;
        z-index: 1;
        font-family: inherit;
        font-size: inherit;
    }

    &-red {
        background: linear-gradient(92.05deg, #D60000 0%, #BF0000 100%);
        box-shadow: 0 0 15px rgba(0,0,0,.15);
        border-radius: 6px; 
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.03) translate3d(0,0,0);
        }

        &:active {
            transform: scale(1) translate3d(0,0,0);
        }
    }

    &-transparent {
        background: transparent;
        border: 2px solid rgba(242, 242, 242, 0.29);
        transition: all .2s ease-in-out;

        &:hover {
            border: 2px solid #D60000;
            background: #D60000;
            transform: scale(1.03) translate3d(0,0,0);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        }

        &:active {
            box-shadow: none;
            transform: scale(1) translate3d(0,0,0);
        }
    }

    &-border {
        color: #000;
        border: 2px solid #C7C7C7;
        background: transparent;
        box-shadow: none;
        transition: all .2s ease-in-out;

        &:hover {
            border: 2px solid #D60000;
            background: #D60000;
            transform: scale(1.03) translate3d(0,0,0);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            color: #fff;
        }

        &:active {
            box-shadow: none;
            transform: scale(1) translate3d(0,0,0);
            color: #fff;
        }
    }

    &-white {
        &-opacity {
            background: rgba(255, 255, 255, 0.1);
            border: 2px solid rgba(242, 242, 242, 0.29);
        }
    }
}
</style>
