/* eslint-disable no-eval */

export default `
Couplings Azuma offers a wide range of main components of the VVT-i system:
- VVT-i coupling;
- Solenoid valve (OCV-Oil Control Valve)
In the production of which high-quality metal alloys with high resistance to friction are used. This ensures minimal wear on the mechanism and extends its service life. VVT-i AZUMA couplings and solenoid valves ensure smooth and reliable operation of the entire engine system.

Special Features and benefits:
* Materials consist of high-quality alloys that have increased wear resistance;
* High-quality lubricant that contributes to minimal friction of mechanisms;
* Reliable protection and tightness of seals;
* Products have passed thermal tests.
`
