<template lang="pug">
#technologies.tech
    .container
        .tech-left
            Logo(id="logotype")
            ul.tech-left-titles
                li.tech-left-items(v-for="item in title[lang]") {{ item }}
            .tech-left-container
                p.tech-left-text {{ text[lang] }}
                ul.tech-list
                    li.tech-list-item(v-for="item in items[lang]") 
                        span.tech-list-title {{ item.title }}
                        span.tech-list-text {{ item.text }}
            Form(
                id="form-tech"
                :inputs="['tel']"
            )
        .tech-right
            Gallery(:images="images")
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import Gallery from './Gallery.vue'
import Logo from './IconLogotype.vue'
import Form from './Form.vue'

import product5 from '../assets/img/tech-11.jpg'
import product4 from '../assets/img/tech-12.jpg'
import product0 from '../assets/img/tech-13.jpg'
import product3 from '../assets/img/tech-14.jpg'
import product2 from '../assets/img/tech-15.jpg'
import product6 from '../assets/img/tech-16.jpg'
import product1 from '../assets/img/tech-17.jpg'
import product7 from '../assets/img/tech-18.jpg'

export default {
	components: {
		Gallery,
		Logo,
		Form
	},

	setup() {
		try {
			const store = useStore()
			const lang = computed(() => store.state.lang)

			const images = [
				product5,
				product4,
				product0,
				product3,
				product2,
				product6,
				product1,
				product7,
				// product8,
				// product9,
				// product10
			]

			const title = {
				eng: [
					'Delivery of products around the world',
					'More than 200 regular customers',
					'Representative offices in 5 countries'
				],
				ru: [
					'Поставка продукции по всему миру',
					'Более 200 постоянных клиентов',
					'Представительства в 5 странах',
				]
			}

			const text = {
				ru: 'Прямые поставки с заводов изготовителей под единым брендом. Высочайший уровень контроля качества. Доступные цены. Аккредитованное представительство в России.',
				eng: 'A combination of high technology and rich experience. Delivery of products to conveyors of various manufacturers. Attention to every detail. The latest equipment in production. High level of control at every stage of production'
			}
            
			const items = {
				eng: [
					{
						title: '>10 000',
						text: 'Articles'
					},
					{
						title: '12',
						text: 'Factories'
					}
				],
				ru: [
					{
						title: '>10 000',
						text: 'Артикулов'
					},
					{
						title: '12',
						text: 'Заводов'
					}
				]
			}

			return {
				images,
				title,
				text,
				items,
				lang
			}
		} catch (err) {
			console.log(err)
			throw (err)
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.tech {
    position: relative;
    background: url('../assets/img/tech-background.jpg') top center / 100% auto no-repeat;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 80px;
        width: 100%;
        height: 34%;
        background: #F4F4F4;
        z-index: -1;
        @media (--viewport-sm) {
            height: 30%;
        }
        @media (--viewport-md) {
            height: 30%;
        }
        @media (--viewport-lg) {
            height: 52%;
            bottom: 88px;
        }
        @media (--viewport-xl) {
            height: 48%;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        position: relative;
        z-index: 1;
        @media (--viewport-md) {
            padding-top: 48px;
        }
        @media (--viewport-lg) {
            flex-direction: row;
            padding-top: 72px;
        }
        @media (--viewport-xl) {
            padding-top: 96px;
        }
    }

    &-list {
        display: flex;
        padding-left: 0;
        justify-content: flex-start;
        margin-top: 0;
        margin-bottom: 56px;

        &-title {
            font-size: 40px;
            font-family: var(--font-condensed);
            font-weight: 600;
            white-space: nowrap;
        }

        &-text {
            font-size: 14px;
            display: block;
            margin-top: 4px;
        }

        &-item {
            list-style-type: none;
            margin-left: 40px;
            width: auto;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    &-left {
        width: 100%;
        padding-top: 56px;
        z-index: 0;
        @media (--viewport-lg) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 50%;
            padding-right: 124px;
            padding-top: 0;
        }

        &-titles {
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 88px;
        }

        &-items {
            list-style-type: none;
            font-size: 28px;
            font-family: var(--font-condensed);
            color: #2e2e2e;
            text-transform: uppercase;
            margin-bottom: 4px;

            &:first-of-type {
                font-weight: 600;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &-text {
            font-size: 15px;
            line-height: 1.5em;
            margin-bottom: 32px;
            max-width: 380px;
        }

        .logotype {
            width: 70px;
            margin-bottom: 32px;

            ::v-deep(path) {
                fill: #E52330;
            }
        }
    }

    &-right {
        width: 100%;
        order: -1;
        z-index: 1;
        @media (--viewport-lg) {
            order: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
        }
    }
}
</style>
