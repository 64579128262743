<template lang="pug">
form.form(@submit.prevent="submit")
	FormInputText(
		v-for="input in inputsList"
		v-model="form[input.name]"
		:type="input.type"
		:name="input.name"
		:label="input.label[lang]"
		:required="input.required"
		:id="input.id"
	)
	Button.form-row(
		type="submit" 
		color="red"
		:loading="inputSubmitData.loading"
		:disabled="inputSubmitData.disabled"
	) {{ buttonSubmitText }} 
	FormCheckbox(
		v-model="form.privacy"
		name="privacy"
		:label="privacyText"
		:required="true"
		:id="`form-${id}-privacy`"
		:checked="true"
	)
</template>

<script>
import axios from 'axios'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import Button from './Button.vue'
import FormInputText from './FormInputText.vue'
import FormCheckbox from './FormCheckbox.vue'

export default {
	components: {
		Button,
		FormInputText,
		FormCheckbox
	},

	props: {
		id: {
			type: String,
			required: true
		},
		inputs: {
			type: Array,
			required: false
		}
	},

	setup(props) {
		try {
			const isDev = process.env.NODE_ENV === 'development'
			const store = useStore()
			const success = ref(false)
			const error = ref('')
			const form = ref({
				tel: '',
				name: '',
				privacy: true
			})
			const inputSubmitData = ref({
				loading: false,
				disabled: false,
			})

			const inputsData = [
				{
					label: {
						eng: 'Your phone number',
						ru: 'Введите номер телефона'
					},
					type: 'tel',
					name: 'tel',
					required: true,
					id: `form-${props.id}-tel`
				},
				{
					label: {
						eng: 'Your name',
						ru: 'Введите ваше имя'
					},
					type: 'text',
					name: 'name',
					required: true,
					id: `form-${props.id}-text`
				}
			]

			const inputsList = inputsData.filter((el) => props.inputs.includes(el.name))
			const lang = computed(() => store.state.lang)

			const buttonText = computed(() => store.state.dataButton[store.state.lang])
			const buttonTextSuccess = computed(() => store.state.dataButtonSuccess[store.state.lang])
			const buttonSubmitText = computed(() => {
				if (success.value) return buttonTextSuccess.value
				return buttonText.value
			})

			const privacyText = computed(() => {
				const content = {
					ru: 'При отправке запроса, Вы соглашаетесь с политикой конфиденциальности',
					eng: 'By submitting an application, you agree to the privacy policy'
				}
				return content[store.state.lang]
			})
			
			const input = (e, name) => { form.value[name] = $event.target.value }
			const submit = async () => {
				try {
					inputSubmitData.value.loading = true
					inputSubmitData.value.disabled = true

					const url = isDev ? 'http://azuma.local/api/send' : '/api/send'
					await axios.post(url, form.value)

					setTimeout(() => {
						success.value = true
						inputSubmitData.value.loading = false
						inputSubmitData.value.disabled = false
						error.value = ''
					}, 300)
				} catch (err) {
					success.value = false
					setTimeout(() => {
						inputSubmitData.value.loading = false
						inputSubmitData.value.disabled = false
					}, 300)
				}
			}

			return {
				buttonText,
				form,
				inputsList,
				privacyText,
				lang,
				submit,
				input,
				inputSubmitData,
				buttonSubmitText,
				success,
				error
			}
		} catch (err) {
			console.log(err)
			throw err
		}
	}
}
</script>

<style lang="postcss" scoped>
.form {
    max-width: 300px;
    width: 100%;
    
    &-row {
        width: 100%;
        margin-bottom: 16px;

        &.button {
            padding-left: 0;
            padding-right: 0;
            height: 48px;
			
			&.button-loading {
				width: 48px;
			}
        }

        ::v-deep(.button-content) {
            width: 100%;
            text-align: left;
            padding-left: 16px;
        }
    }
}
</style>
