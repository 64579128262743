/* eslint-disable no-eval */

export default `
The Full gasket set of the engine AZUMA includes a set of pads, oil seal, valve stem seals, ?, sealing rubbers. The Full gasket set is made from original patterns. Provides a reliable operation of engine components. Thanks to the special rubber composition of the gaskets, the oil seals are protected from cracking and corrosion for a long period of time. Asbestos and parasitic products are made out of materials of the highest quality and standard, which provides excellent heat resistance and elasticity. Oil seals have the ability to fully protect dirt from entering the engine.Valve stem seals are not in any way inferior to the leading Japanese manufacturers.

Special features and Benefits:

* Resistant to temperature changes;
* Provides 100%;
* Has great resource;
* Withstands severe engine operations.
`
