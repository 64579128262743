/* eslint-disable no-eval */

export default `
AZUMA brake discs are fully compatible with the original brake systems and have an excellent balance between performance and comfort of braking under normal operating conditions, both at high and low loads.
They are made of high-carbon steel and provide minimal brake wear in combination with AZUMA pads.


Special Features and benefits:
* Controlled braking and high brake sensitivity;
* High effective brake dynamics;
* Good resistance to cracking and deformation;
* No beating or vibration during braking.
`
