<template lang="pug">
footer.footer
	.container
		.footer-about
			Logo(id="footer-logotype")
			span.footer-company {{ company.name[lang] }}
			span.footer-cc {{ company.cc[lang] }}
			span.footer-cc {{ company.right[lang] }}
			noindex
				span.footer-dev {{ develop[lang] }} 
					a(href="https://creativecustom.ru" target="_blank") Creative Custom
		.footer-req
			span.footer-req-text {{ company.req[lang] }}
			ul.footer-req-list
				li.footer-req-item(v-for="item in company.details[lang]") {{ item }}
			noindex
				span.footer-dev {{ develop[lang] }} 
					a(href="https://creativecustom.ru" target="_blank") Creative Custom
		.footer-right   
			.footer-contacts
				Contact(
					v-for="contact in contacts"
					color="black"
					:link="contact.link"
					:num="contact.name"
					:text="contact.text"
				)
			.footer-address
				.footer-lang 
					span.footer-lang-text {{ language[lang] }}
					ul.footer-lang-list
						li.footer-lang-item(
							v-for="item in langs"
							:class="item === lang ? 'active' : ''"
							@click="changeLang(item)"
						) {{ item }}
				Address(
					v-for="item in address"
					:address="item"
				)
			noindex
				span.footer-dev {{ develop[lang] }} 
					a(href="https://creativecustom.ru" target="_blank") Creative Custom
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import Logo from './IconLogotype.vue'
import Contact from './Contacts.vue'
import Address from './ContactsAddress.vue'

export default {
	components: {
		Logo,
		Contact,
		Address
	},

	setup() {
		const store = useStore()
		const lang = computed(() => store.state.lang)
		const changeLang = (langName) => store.commit('changeLang', langName)
		const langs = ['eng', 'ru']

		const company = {
			name: {
				eng: 'LLC "Automechanika"',
				ru: 'Группа компаний Автомеханика'
			},
			cc: {
				eng: 'Copyright © 2020',
				ru: 'Copyright © 2020'
			},
			right: {
				eng: 'All rights reserved',
				ru: 'Все права защищены'
			},
			details: {
				eng: [
					'INN 2538067373',
					'OGRN 1022501900618',
					'KPP 253801001',
					'OKATO 05401369000',
					'OKPO 57827765',
				],
				ru: [
					'ИНН 2538067373',
					'ОГРН 1022501900618',
					'КПП 253801001',
					'ОКАТО 05401369000',
					'ОКПО 57827765'
				]
			},
			req: {
				ru: 'Реквизиты компании',
				eng: 'Company details'
			}
		}

		const language = {
			eng: 'Сhoose your language',
			ru: 'Выберите язык'
		}
		const { address } = store.state.contacts
		const contacts = computed(() => store.state.contacts.phones)
		const develop = {
			eng: 'Developed by',
			ru: 'Разработано в'
		}

		return {
			lang,
			langs,
			company,
			contacts,
			address,
			language,
			changeLang,
			develop
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.footer {
    --footer-padding: 72px;
    --footer-padding-content: 40px;
    padding-top: var(--footer-padding);
    padding-bottom: var(--footer-padding);
    background: url('../assets/img/background-banner.jpg') center / cover no-repeat rgb(0, 54, 116);
    @media (--viewport-lg) {
        padding-top: 0;
        padding-bottom: 0;
    }
    @media (--viewport-xl) {
        --footer-padding: 96px;
    }

    *,
    ::v-deep(*) {
        color: #fff !important;
    }

    .container {
        display: flex;
        flex-direction: column;
        @media (--viewport-md) {
            flex-flow: row wrap;
        }
        @media (--viewport-lg) {
            flex-flow: row nowrap;
            padding-bottom: 72px;
        }
    }

	&-dev {
		display: block;
		font-size: 13px;
		font-weight: 300;
		margin-top: 48px;
		white-space: nowrap;
		
		a {
			font-weight: bold;
			font-size: 13px;
			text-decoration: underline;
			white-space: nowrap;
		}
	}

    &-about {
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        @media (--viewport-md) {
            width: 50%;
        }
        @media (--viewport-lg) {
            padding-top: var(--footer-padding);
            width: auto;
            border-right: 1px solid rgba(255, 255, 255, .1);
            padding-right: 48px;
            border-bottom: none;
            padding-bottom: 0;
        }
		
		.footer-dev {
			display: none;
			@media (--viewport-lg) {
				display: block;
				margin-top: 32px;
			}
			@media (--viewport-xl) {
				margin-top: 40px;
			}
		}
    }

    &-company {
        display: flex;
        width: 100%;
        line-height: 1.5em;
        font-size: 16px;
        font-weight: 600;
    }

    &-cc {
        display: flex;
        width: 100%;
        line-height: 1.5em;
        font-size: 14px;
        font-weight: 300;
    }

    &-req {
        order: 1;
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        @media (--viewport-sm) {
            border-top: 1px solid rgba(255, 255, 255, .1);
        }
        @media (--viewport-md) {
            order: 0;
            width: 50%;
            padding-top: 0;
            border-top: none;
        }
        @media (--viewport-lg) {
            padding-top: var(--footer-padding);
            margin-left: 0;
            width: auto;
            padding-left: 48px;
            padding-bottom: 0;
            border-bottom: none;
        }
        @media (--viewport-xl) {
            padding-left: 64px;
            padding-right: 64px;
            border-right: 1px solid rgba(255, 255, 255, .1);
        }

        &-text {
            font-weight: 700;
            font-size: 15px;
            margin-bottom: 24px;
            display: block;
            @media (--viewport-md) {
                text-align: right;
            }
            @media (--viewport-lg) {
                text-align: left;
            }
            @media (--viewport-xl) {
                white-space: nowrap;
            }
        }

        &-item {
            list-style-type: none;
            line-height: 1.4em;
            margin-bottom: 4px;
            font-weight: 300;
            font-size: 15px;
            @media (--viewport-md) {
                text-align: right;
            }
            @media (--viewport-lg) {
                text-align: left;
                white-space: nowrap;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &-list {
            font-size: 15px;
            font-weigth: 300;
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

		.footer-dev {
			display: block;
			@media (--viewport-md) {
				display: none;
			}
		}
    }

    .logotype {
        width: 134px;
        margin-bottom: 24px;
    }

    &-contacts {
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        @media (--viewport-sm) {
            border-bottom: 0;
        }
        @media (--viewport-md) {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 40px;
            border-bottom: 1px solid rgba(255, 255, 255, .1);
        }
        @media (--viewport-xl) {
            padding-left: 56px;
        }
    }

    .contact {
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        @media (--viewport-md) {
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        ::v-deep{
            &(.contact-icon) {
                display: none;
            }

            &(.contact-num) {
                font-size: 22px;
                @media (--viewport-lg) {
                    font-size: 19px;
                }
                @media (--viewport-xl) {
                    font-size: 23px;
                }
            }

            &(.contact-text) {
                font-size: 12px;
                font-weight: 300;
                margin-top: 4px;
                display: block;
            }
        }
    }

    &-address {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        @media (--viewport-sm) {
            flex-flow: column nowrap;
            margin-left: auto;
            justify-content: flex-end;
        }
        @media (--viewport-md) {
            flex-flow: row wrap;
            margin-left: 0;
            width: 100%;
            padding-top: 40px;
        }
    }

    .address {
        order: 0;
        width: calc(100% / 2);
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        @media (--viewport-sm) {
            width: 70%;
            border-bottom: none;
            padding-bottom: 24px;
            padding-top: 0;
            margin-left: auto;
        }
        @media (--viewport-md) {
            width: calc(100% / 3);
            padding-left: 40px;
        }
        @media (--viewport-lg) {
            padding-left: 0;
            padding-bottom: 0;
        }
        @media (--viewport-xl) {
            padding-left: 32px;
            padding-bottom: 0;
            padding-bottom: 0;
        }

        ::v-deep {
        
            &(.address-address) {
                font-size: 12px;
                @media (--viewport-xl) {
                    font-size: 13px;
                    line-height: 1.5em;
                }
            }

            &(.address-country) {
                @media (--viewport-xl) {
                    margin-bottom: 8px;
                }
            }

            &(span) {
                @media (--viewport-md) {
                    text-align: left;
                }
            }
        }

        &:last-of-type {
            padding-left: 16px;
            @media (--viewport-sm) {
                padding-left: 0;
            }
            @media (--viewport-md) {
                padding-left: 40px;
            }
            @media (--viewport-lg) {
                padding-left: 32px;
            }
        }

        &:first-of-type {
            padding-right: 16px;
            @media (--viewport-sm) {
                padding-right: 0;
            }

            ::v-deep {
                &(span) {
                    @media (--viewport-sm-max) {
                        text-align: left;
                    }
                    @media (--viewport-md) {
                        text-align: left;
                    }
                }
            }
        }
    }

    &-right {
        order: 0;
        padding-top: var(--footer-padding-content);
        @media (--viewport-sm) {
            display: flex;
        }
        @media (--viewport-md) {
            width: 100%;
            flex-flow: row wrap;
        }
        @media (--viewport-lg) {
            flex-grow: 1;
            width: auto;
            margin-left: 48px;
            padding-top: 72px;
        }
        @media (--viewport-xl) {
            padding-left: 64px;
            margin-left: 0;
        }

		.footer-dev {
			display: none;
			@media (--viewport-md) {
				display: block;
				margin-top: 0;
				margin-left: auto;
				align-items: flex-end;
				width: 100%;
			}
			@media (--viewport-lg) {
				display: none;
			}
		}
    }

    &-lang {
        order: 1;
        margin-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        width: 100%;
        display: flex;
        flex-direction: column;
        @media (--viewport-sm) {
            margin-left: auto;
            width: auto;
            border-bottom: 0;
        }
        @media (--viewport-md) {
            order: 0;
            margin-left: 0;
            margin-right: auto;
            margin-top: 0;
            padding-top: 0;
            width: calc(100% / 3);
        }
        @media (--viewport-lg) {
            padding-bottom: 0;
        }
        @media (--viewport-xl) {
            padding-left: 56px;
        }

        &-list {
            padding-left: 0;
            display: flex;
            justify-content: flex-start;
            margin-top: 16px;
            margin-bottom: 0;
            @media (--viewport-sm) {
                margin-left: auto;
            }
            @media (--viewport-md) {
                margin-left: 0;
                margin-right: auto;
                margin-top: 8px;
            }
            @media (--viewport-xl) {
                margin-top: 16px;
            }

            &:hover {
                .footer-lang-item {
                    &:not(:hover) {
                        font-weight: 300 !important;
                    }
                }
            }
        }

        &-text {
            font-size: 12px;
            font-weight: 300;
            @media (--viewport-xl) {
                font-size: 13px;
            }
        }

        &-item {
            font-size: 15px;
            font-weight: 300;
            text-transform: uppercase;
            list-style-type: none;
            border-right: 1px solid rgba(255, 255, 255, .1);
            margin-right: 24px;
            padding-right: 24px;
            width: auto;
            cursor: pointer;
            transition: all .3s ease-in-out;

            &:hover {
                font-weight: bold;
            }

            &:last-of-type {
                border-right: 0;
                @media (--viewport-sm) {
                    padding-right: 0;
                    margin-right: 0;
                }
            }

            &.active {
                font-weight: bold;
            }
        }
    }
}
</style>
