<template lang="pug">
.product-modal
    .product-image  
        img(v-if="product.img.modal" :src="product.img.modal")
    .product-content
        .container
            span.product-title {{ product.title[lang] }}
            .product-content-text(v-html="res")
            FormProduct(
                :id="product.id" 
                :inputs="['tel']"
            )
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import MarkdownIt from 'markdown-it'
import FormProduct from './Form.vue'

export default {
	components: {
		FormProduct
	},

	props: {
		id: {
			required: true
		}
	},

	setup(props) {
		const store = useStore()
		const lang = computed(() => store.state.lang)
		const md = new MarkdownIt()

		const product = computed(() => {
			const data = store.state.products.find((el) => el.id === props.id)
			if (!data) return {}
			return data
		})

		const res = computed(() => md.render(product.value.content[lang.value]))

		return {
			product,
			lang,
			res
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.product {

    &-modal {
        display: flex;
        flex-direction: column;
        @media (--viewport-md) {
            flex-direction: row;
        }
    }

    &-content,
    &-image {
        max-width: 1000px;
    }

    &-image {
        width: 100%;
        display: flex;
        background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
        height: 290px;
        position: relative;
        @media (--viewport-md) {
            flex: 0 0 30%;
            height: auto;
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
        }
        @media (--viewport-xl) {
            flex: 0 0 375px;
        }

        img {
            display: block;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            margin-top: 24px;
            max-height: 300px;
            position: absolute;
            @media (--viewport-md) {
                top: 50%;
                transform: translate3d(0,-50%,0);
                left: 16px;
                width: calc(100% + 40px);
            }
        }
    }

    &-content {
        padding-top: 48px;
        padding-bottom: 48px;
        background: #fff;
        @media (--viewport-md) {
            flex-grow: 1;
            padding-top: 56px;
            padding-bottom: 56px;
            padding-left: 56px;
            padding-right: 48px;
        }
        @media (--viewport-lg) {
            padding-top: 64px;
            padding-bottom: 64px;
            padding-left: 80px;
            padding-right: 56px;
        }
        @media (--viewport-xl) {
            padding-top: 72px;
            padding-bottom: 72px;
            padding-left: 104px;
            padding-right: 72px;
        }

        .container {
            @media (--viewport-md) {
                max-width: 100%;
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }

        .form {
            margin-top: 56px;
        }

        ::v-deep(p), 
        ::v-deep(span),
        ::v-deep(a),
        ::v-deep(li),
        ::v-deep(ol) {
            font-size: 16px;
            font-weight: 400;
            color: #000;
            line-height: 1.6em;

            &.product-title {
                display: block;
                font-size: 40px;
                text-transform: uppercase;
                font-weight: 600;
                font-family: var(--font-condensed);
                color: #474747;
                margin-bottom: 40px;
            }
        }
    }
}
</style>
