<template lang="pug">
.modal-form
    .modal-form-left    
        Contact(
            v-for="contact in contacts"
            color="black"
            :link="contact.link"
            :num="contact.name"
            :text="contact.text"
        )
        Address(
            v-for="item in address"
            :address="item"
        )
    .modal-form-right
        span.modal-form-title {{ title }}
        p.modal-form-text {{ titleText }}
        Form(
            :id="id"
            :inputs="['tel', 'name']"
        )
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue' 
import Form from './Form.vue'
import Contact from './Contacts.vue'
import Address from './ContactsAddress.vue'

export default {
	props: {
		id: {
			type: String,
			required: true
		}
	},

	components: {
		Form,
		Contact,
		Address
	},

	setup() {
		const store = useStore()
		const contacts = computed(() => [
			...store.state.contacts.phones, 
			...store.state.contacts.email
		])
		const { address } = store.state.contacts
		const title = computed(() => {
			if (store.state.lang === 'ru') return 'Свяжитесь с нами'
			return 'Contact us'
		})
		const titleText = computed(() => {
			if (store.state.lang === 'ru') return 'Отправьте ваши контакты, наш менеджер свяжется с Вами в ближайшее время'
			return 'Send your contacts, our manager will contact you shortly'
		})

		return {
			contacts,
			address,
			title,
			titleText
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.modal-form {
    display: flex;
    flex-direction: column;
    @media (--viewport-sm-max) {
        min-height: 100vh;
    }
    @media (--viewport-sm) {
        flex-direction: row;
    }

    &-title {
        font-family: var(--font-condensed);
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
        text-transform: uppercase;
        color: #474747;
        margin-bottom: 32px;
        display: block;
        max-width: 300px;
    }

    &-text {
        font-size: 16px;
        color: #474747;
        margin-bottom: 32px;
        display: block;
        max-width: 300px;
        @media (--viewport-sm) {
            margin-bottom: 40px;
        }
        @media (--viewport-md) {
            margin-bottom: 48px;
        }
        @media (--viewport-lg) {
            margin-bottom: 56px;
        }
    }

    &-left {
        width: 100%;
        background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
        padding-top: 72px;
        padding-bottom: 72px;
        order: 1;
        margin-top: auto;
        @media (--viewport-sm-max) {
            padding-left: var(--container-padding);
            padding-right: var(--container-padding);
            padding-top: 56px;
            width: 100%;
        }
        @media (--viewport-sm) {
            order: 0;
            padding-top: 72px;
            padding-bottom: 72px;
            padding-left: 56px;
            padding-right: 56px;
            width: 45%;
            flex: 0 0 45%;
            max-width: 375px;
        }
        @media (--viewport-md) {
            padding-right: 80px;
        }

        .address {
            margin-bottom: 24px;
            max-width: 200px;
            margin-left: auto;
            @media (--viewport-sm-max) {
                width: 100%;
                margin-left: 0;
                max-width: 100%;
            }

            &:first-of-type {
                padding-top: 32px;
                border-top: 1px solid rgba(0,0,0,.1);
                margin-top: 32px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            ::v-deep(span) {
                @media (--viewport-sm-max) {
                    text-align: left;
                }
            }
        }

        .contact {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            align-items: flex-end;
            margin-left: auto;
            @media (--viewport-sm-max) {
                align-items: flex-end;
            }

            ::v-deep(span) {
                text-align: right;
                @media (--viewport-sm-max) {
                    text-align: left;
                }
            }

            &.contact-mailto {
                ::v-deep(.contact) {
                    &-num {
                        font-size: 19px !important;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        ::v-deep(.contact) {
            &-icon {
                display: none;
            }

            &-num {
                font-size: 31px !important;
                font-family: var(--font-condensed) !important;
                font-weight: 400;
            }

            &-text {
                font-size: 12px !important;
                margin-top: 4px;
                display: block;
            }
        }
    }       

    &-right {
        order: 0;
        @media (--viewport-sm-max) {
            padding-left: var(--container-padding);
            padding-right: var(--container-padding);
            padding-top: 72px;
            width: 100%;
        }
        @media (--viewport-sm) {
            order: 1;
            padding-top: 72px;
            padding-bottom: 72px;
            padding-left: 56px;
            padding-right: 56px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .form {
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding-bottom: 56px;
            @media (--viewport-sm) {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
</style>
