<template lang="pug">
a.contact(
    :href="link"
    :class="className"
)
    component.contact-icon(
        :is="icon"
        :id="`contact-${link}`"
    )
    span.contact-num(v-if="num") {{ num }}
    span.contact-text(v-if="txt") {{ txt }}
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import IconPhone from './IconPhone.vue'
import IconEmail from './IconMessage.vue'

export default {
	components: {
		IconPhone,
		IconEmail
	},

	props: {
		num: {
			type: String,
			required: true
		},
		link: {
			type: String,
			required: true
		},
		text: {
			required: false
		},
		color: {
			type: String,
			required: false
		}
	},

	setup(props) {
		try {
			const store = useStore()
			const { lang } = store.state
			const className = ref([])
			const link = ref('')
			link.value = `${props.link}:${props.num.replace(/[\s+\-()]/g, '')}`

			const icon = computed(() => {
				switch (props.link) {
				case 'tel': return 'IconPhone'
				case 'mailto': return 'IconEmail'
				default: return null
				}
			})

			const txt = computed(() => {
				if (typeof props.text === 'object') return props.text[store.state.lang]
				return props.text
			})

			className.value.push(`contact-${props.link}`)
			className.value.push(lang)
			if (props.color) className.value.push(`contact-${props.color}`)
            
			return {
				link,
				icon,
				className,
				txt
			}
		} catch (err) {
			console.log(err)
			throw err
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.contact {
    dipslay: flex;

    &-black {
        .contact {
            &-num,
            &-text { 
                color: #474747; 
            }
        }
    }

    &.contact-tel {
        .contact-num {
            font-family: var(--font-title-eng);
            @media (--viewport-sm) {
                font-size: 24px;
            }
            @media (--viewport-lg) {
                font-size: 22px;
            }
        }
    }

    &.contact-mailto {
        .contact-num {
            font-weight: 300;
            font-size: 14px;
            @media (--viewport-lg) {
                font-size: 13px;
            }
        }
    }

    &-num {
        display: block;
        white-space: nowrap;
        color: #fff;
    }

    &-text {
        display: block;
        white-space: nowrap;
        color: #fff;
    }
}
</style>
