<template lang="pug">
section.catalog(
    :class="className"
    ref="el"
)
    .container
        p.catalog-title {{ title.title }}
            span {{ title.titleSub }}
        ul.catalog-list
            template(v-for="item in items")
                li.catalog-list-item(
                    v-if="item.title"
                    @click="changeModalStatus(item.id)"
                ) {{ item.title }}
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import scrollMonitor from 'scrollmonitor'

export default {
	setup() {
		const store = useStore()
		const items = computed(() => store.getters.productsList)
		const show = ref(false)
        
		const title = computed(() => {
			const obj = {
				title: { ru: 'Каталог', eng: 'Product' },
				titleSub: { ru: 'Продукции', eng: 'Catalog' }
			}

			return {
				title: obj.title[store.state.lang],
				titleSub: obj.titleSub[store.state.lang]
			}
		})

		const el = ref(null)

		const changeModalStatus = (id) => {
			store.commit('changeModalStatus', true)
			store.commit('changeModalType', 'product')
			store.commit('changeModalProduct', id)
		}

		const className = computed(() => (show.value ? 'show' : false))

		onMounted(() => {
			const watcher = scrollMonitor.create(el.value)
			watcher.enterViewport(() => {
				show.value = true
			})
		})

		return {
			items,
			title,
			changeModalStatus,
			className,
			el,
			show
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';
@import '../assets/css/animation.css';

.catalog {
    position: relative;
    background: url('../assets/img/background-product-catalog.jpg') center / auto 100% no-repeat;
    opacity: 0;
    @media (--viewport-md-max) {
        background-size: auto 200px;
        background-position: 10% 100%;
    }
    @media (--viewport-md) {
        background-position: 20% 100%;
        background-size: auto 250px;
    }
    @media (--viewport-lg-xl) {
        background-size: auto 240px;
    }
    @media (--viewport-lg) {
        background-position: center center;
        background-size: auto 90%;
    }

    &.show {
        animation: showDownTop .7s .3s ease-in-out forwards;   
    }

    &:before {
        content: '';
        z-index: 1;
        background: linear-gradient(to top, #F7F7F7 0.39%, rgba(255, 255, 255, 0) 100%);
        height: 200px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        @media (--viewport-md) {
            height: 100%;
        }
    }

    .container {
        --padding-catalog-top: 72px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: var(--padding-catalog-top);
        padding-bottom: var(--padding-catalog-top);
        margin-top: 72px;
        border-top: 1px solid rgba(0,0,0,.1);
        z-index: 1;
        @media (--viewport-md) {
            --padding-catalog-top: 80px;
            padding-bottom: 0;
            flex-direction: row;
            align-items: stretch;
            border-top: 0;
            margin-top: 0;
        }
        @media (--viewport-lg) {
            --padding-catalog-top: 96px;
        }
    }

    &-title {
        display: flex;
        flex-direction: column;
        font-family: var(--font-condensed);
        font-weight: 600;
        font-size: 51px;
        line-height: 45px;
        text-transform: uppercase;
        color: #2E2E2E;
        margin-bottom: 40px;
        @media (--viewport-md) {
            border-right: 1px solid rgba(0,0,0,.1);
            padding-right: 48px;
            padding-bottom: var(--padding-catalog-top);
            margin-bottom: 0;
            width: auto;
        }
        @media (--viewport-lg) {
            padding-right: 96px;
        }
        @media (--viewport-xl) {
            padding-right: 164px;
        }

        span {
            font-family: var(--font-condensed);
            font-weight: 300;
            font-size: 41px;
        }
    }

    &-list {
        display: flex;
        flex-flow: row wrap;
        padding-left: 0;
        flex-grow: 1;
        margin: 0;
        @media (--viewport-md) {
            flex-grow: 1;
            padding-left: 64px;
            margin-left: auto;
            margin-bottom: auto;
            display: block;
            column-count: 2;
            padding-bottom: var(--padding-catalog-top);
        }
        @media (--viewport-lg) {
            column-count: 3;
            padding-left: 96px;
        }
        @media (--viewport-xl) {
            column-count: 4;
            padding-left: 80px;
        }

        &-item {
            display: flex;
            font-family: var(--font-condensed);
            font-size: 18px;
            text-transform: uppercase;
            list-style-type: none;
            padding-left: 0;
            width: 50%;
            transition: all .15s ease-in-out;
            cursor: pointer;
            font-weight: 300;
            @media (--viewport-sm) {
                width: calc(100% / 3);
            }
            @media (--viewport-md) {
                margin-bottom: 8px !important;
                width: 100%;
                font-size: 19px;
            }
            @media (--viewport-lg) {
                padding-right: 8px;
                font-size: 20px;
            }
            @media (--viewport-xl) {
                padding-right: 0 !important;
                font-size: 21px;
                text-align: right;
                margin-left: auto;
                justify-content: flex-end;
                padding-left: 32px;
            }

            &:hover {
                font-weight: 600;
            }

            &:nth-child(8n) {
                margin-bottom: 24px;
                @media (--viewport-sm) {
                    margin-bottom: 0;
                }
            }

            &:nth-child(2n) {
                padding-left: 32px;
                @media (--viewport-sm) {
                    padding-left: 0;
                }
            }

            &:nth-child(2n + 1) {
                padding-right: 32px;
                @media (--viewport-sm) {
                    padding-right: 0;
                }
            }
        }
    }
}
</style>
