<template lang="pug">
nav.header-nav
    ul.header-nav-list(ref="navEl")
        template(v-for="(item, index) in navTab")
            li.header-nav-item(v-if="index < 2")
                a(:href="item.url") {{ item.name }}
        li.header-nav-item.header-nav-logo
            Logo(id="header-nav")
        template(v-for="(item, index) in navTab")
            li.header-nav-item(v-if="index >= 2")
                a(:href="item.url") {{ item.name }}
</template>

<script>
import { ref, onMounted } from 'vue'
import { getNavTab } from '../composables/TheFirstContent'
import hammer from '../assets/js/hammer'
import Logo from './IconLogotype.vue'

export default {
	components: {
		Logo
	},

	setup() {
		try { 
			const { tabs } = getNavTab()
			const navTab = ref(tabs)
			const navEl = ref(null)
            
			onMounted(() => {
				hammer(navEl.value)
			})

			return {
				navTab,
				navEl
			}
		} catch (err) {
			console.log(err)
			throw err
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.header {
    &-nav {
        display: flex;
        @media (--viewport-sm-md) {
            padding-left: var(--container-padding);
            padding-right: var(--container-padding);
        }
        @media (--viewport-md-max) {
            width: 100%;
        }

        &-list {
            display: flex;
            padding: 0;
            margin: 0;
            align-items: center;
            @media (--viewport-sm-max) {
                padding-left: var(--container-padding);
                padding-right: var(--container-padding);
            }
            @media (--viewport-sm) {
                border-top: var(--header-nav-link);
                width: 100%;
                justify-content: center;
            }
            @media (--viewport-md) {
                border-top: 0;
            }

            &.nopan {
                @media (--viewport-sm-max) {
                    min-width: 100%;
                }
            }
        }

        &-item {
            list-style-type: none;
            @media (--viewport-sm) {
                width: auto;
            }
            @media (--viewport-lg) {
                width: 20%;
            }

            &.header-nav-logo {
                display: none;
                @media (--viewport-lg) {
                    display: block;
                    width: 96px;
                    height: 27px;
                    margin-left: 24px;
                    margin-right: 24px;
                }
            }

            &:first-of-type {
                a {
                    @media (--viewport-sm-max) { 
                        padding-left: 0; 
                    }
                    @media (--viewport-md) { padding-left: 0; }
                    @media (--viewport-lg) {
                        padding-left: 16px;
                    }
                }
            }

            &:last-of-type {
                a {
                    @media (--viewport-sm-max) { padding-right: 0; }
                }
            }

            a {
                display: block;
                color: #fff;
                font-family: var(--font-condensed-expanded);
                font-size: 17px;
                font-weight: 600;
                font-style: normal;
                text-transform: uppercase;
                padding: 8px 16px 24px 16px;
                border-bottom: var(--header-nav-link);
                position: relative;
                transition: all .3s ease-in-out;
                white-space: nowrap;
                @media (--viewport-375) {
                    font-size: 19px;
                }
                @media (--viewport-sm) {
                    border-bottom: none;
                    padding: 24px 16px 8px 16px;
                    font-size: 19px;
                }
                @media (--viewport-md) {
                    padding: 23px 16px;
                    font-weight: 300;
                    font-family: var(--font-condensed);
                }
                @media (--viewport-lg) {
                    padding-top: 40px;
                    padding-bottom: 40px;
                    width: 100%;
                    text-align: center;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    background: linear-gradient(90deg, #D60000 0%, #BF0000 100%);
                    height: 0;
                    width: 100%;
                    transition: all .15s ease-in-out;
                    display: block;
                    @media (--viewport-sm) {
                        bottom: auto;
                        top: -4px;
                    }
                    @media (--viewport-md) {
                        bottom: -4px;
                        top: auto;
                    }
                    @media (--viewport-lg) {
                        bottom: 1px;
                    }
                }

                &:hover {
                    transform: scale(1.05) translate3d(0,0,0);
                    &:before {
                        height: 2px;
                        bottom: -1px;
                        @media (--viewport-sm) {
                            bottom: auto;
                            top: -1px;
                        }   
                        @media (--viewport-md) {
                            bottom: -1px;
                            top: auto;
                        }
                        @media (--viewport-lg) {
                            bottom: 1px;
                        }
                    }
                }

                &:active {
                    transform: scale(1) translate3d(0,0,0);
                }
            }
        }
    }
}
</style>
