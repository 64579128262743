<template lang="pug"> 
p.address
    span.address-country {{ data.country }}
    span.address-address {{ data.address }}
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
	props: {
		address: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		const store = useStore()
		const data = computed(() => ({
			country: props.address.country[store.state.lang],
			address: props.address.address[store.state.lang]
		}))

		return {
			data
		}
	}
}
</script>

<style lang="postcss" scoped>
.address {
    display: flex;
    flex-direction: column;

    &-address {
        font-weight: 300;
        font-size: 13px;
        line-height: 140%;
        text-align: right;
        color: #000000;
    }

    &-country {
        font-weight: 900;
        font-size: 15px;
        line-height: 25px;
        text-align: right;
        color: #000000;
    }
}
</style>
