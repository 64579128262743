/* eslint-disable no-eval */

export default `
Тормозные колодки AZUMA представляют собой многокомпонентную систему, благодаря которому они имеют превосходные фрикционно-износные свойства: равномерно изнашиваются и обеспечивают стабильные характеристики при движении с различной интенсивностью на протяжении всего срока службы.


Особенности и преимущества:

* Высокая эффективность фрикционных свойств;
* Акустический комфорт;
* Низкий уровень пыли;
* Высокотехнологичный состав материала.
`
