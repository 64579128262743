/* eslint-disable no-eval */

export default `
AZUMA brake pads are a multi-component system, thanks to which they have excellent friction and wear properties: they wear evenly and provide stable performance when driving with different intensity throughout their service life.


Special Features and benefits:
* High efficiency of friction properties;
* Acoustic comfort;
* Low dust level;
* High-tech material composition.
`
