<template lang="pug">
TheFirst
TheProducts
TheCatalog
TheBanner
TheTech
TheAdvantages
TheFooter
TheModal
FixedNavigation
</template>

<script>
import 'normalize.css'
import TheFirst from '../components/TheFirst.vue'
import TheProducts from '../components/TheProducts.vue'
import TheModal from '../components/TheModal.vue'
import TheCatalog from '../components/TheCatalog.vue'
import TheBanner from '../components/TheBanner.vue'
import TheTech from '../components/TheTechnologies.vue'
import TheAdvantages from '../components/TheAdvantages.vue'
import TheFooter from '../components/TheFooter.vue'
import FixedNavigation from '../components/FixedNavigation.vue'

export default {
	name: 'App',

	components: {
		TheFirst,
		TheProducts,
		TheModal,
		TheCatalog,
		TheBanner,
		TheTech,
		TheAdvantages,
		TheFooter,
		FixedNavigation
	}
}
</script>

<style lang="postcss">
.fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	background: #000;
	span {
		color: #fff;
		margin-right: 40px;
	}
}
</style>
