<template lang="pug">  
IconBase.logotype(
    iconName="Contact message icon"
    viewBox="0 0 26 18"
)
    path(d='M23.7692 0.999996H2.23079C1.55383 0.999996 1 1.55382 1 2.23074V15.7692C1 16.4461 1.55383 17 2.23079 17H23.7692C24.4461 17 25 16.4462 25 15.7692V2.23074C25 1.55382 24.4461 0.999996 23.7692 0.999996ZM23.3073 1.92301L13.708 9.12309C13.5354 9.25443 13.2704 9.3366 12.9999 9.33539C12.7295 9.3366 12.4646 9.25443 12.2919 9.12309L2.69261 1.92301H23.3073ZM18.1797 9.59817L23.4104 16.0597C23.4157 16.0662 23.4221 16.0711 23.4277 16.077H2.57228C2.57781 16.0708 2.58428 16.0662 2.58953 16.0597L7.8203 9.59817C7.98056 9.40003 7.95019 9.10954 7.75167 8.9489C7.55353 8.78864 7.26305 8.81901 7.10274 9.0172L1.92306 15.4157V2.49995L11.7384 9.86146C12.1073 10.1362 12.5562 10.2572 12.9999 10.2584C13.4429 10.2575 13.8922 10.1365 14.2614 9.86146L24.0767 2.49995V15.4156L18.8972 9.0172C18.7369 8.81906 18.4461 8.78859 18.2482 8.9489C18.0498 9.10917 18.0193 9.40003 18.1797 9.59817Z' fill='white')
</template>

<script>
import IconBase from './IconBase.vue'

export default {
	components: {
		IconBase
	},

	props: {
		id: {
			type: String,
			required: true
		}
	}, 
}
</script>

<style>

</style>
