/* eslint-disable no-eval */

export default `
Воздушные фильтры AZUMA гарантируют оптимальное приготовление топливно-воздушной смеси, обеспечивая качественную очистку поступающего в двигатель воздуха. На сегодняшний день ассортимент AZUMA насчитывает свыше 200 наименований воздушных фильтров для наиболее популярных  автомобилей.

Каркас воздушного фильтра выполнен из специального полиуретана, который обладает высокой эластичностью и повышенной прочностью (материла не трескает и не твердеет), устойчив у высоким и низким температурам.
Комбинация волокон и особой пропитки обеспечивает уникальные свойства фильтрующего материала, повышая эффективность фильтрации.

Особенности и преимущества:
* Современный технологии производства;
* Точное соответствие установочным размерам автомобиля;
* Высокая термическая стабильность и надежность.
`
