<template lang="pug">
section.section.first(
    :class="className"
    ref="first"
)
    TheHeader
    .container.first-container
        h1.first-title
            span(v-for="titleItem in title.title") {{ titleItem }}
        p.first-title-sub {{ title.titleSub }}
        Button(
            color="white-opacity"
            @click="changeModalStatus"
        ) {{ btnText }}
        Contact(
            v-for="contact in contacts"
            :link="contact.link"
            :num="contact.name"
        )
    TheFirstProductList
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'

import scrollMonitor from 'scrollmonitor'
import Logotype from './IconLogotype.vue'
import Button from './Button.vue'
import Contact from './Contacts.vue'
import TheFirstProductList from './TheFirstProductList.vue'
import TheHeader from './TheHeader.vue'
import { getFirstContent } from '../composables/TheFirstContent'

export default {
	components: {
		Logotype,
		Button,
		Contact,
		TheFirstProductList,
		TheHeader
	},

	setup() {
		try {
			const store = useStore()
			const contacts = store.getters.contactsMain
			const { title } = getFirstContent()
			const first = ref(null)
			const show = ref(false)
			const changeModalStatus = () => {
				store.commit('changeModalStatus', true)
				store.commit('changeModalType', 'form')
			}

			const btnText = computed(() => store.state.dataButton[store.state.lang])

			const className = computed(() => [
				`first-lang-${store.state.lang}`,
				show.value ? 'show' : false
			])

			onMounted(() => {
				const watcher = scrollMonitor.create(first.value)
				watcher.enterViewport(() => {
					show.value = true
				})
			})

			return {
				title,
				contacts,
				className,
				btnText,
				first,
				show,
				changeModalStatus
			}
		} catch (err) {
			console.log(err)
			throw err
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';
@import '../assets/css/animation.css';

.section {
    background: url('../assets/img/background-first.jpg') center / cover no-repeat rgb(0, 54, 116);
    overflow: hidden;

    &.show {
        .first-title {
            animation: showDownTop .7s .2s ease-in-out both;   
            &-sub {
                animation: showDownTop .7s .25s ease-in-out both;   
            }
        }

        .product-list {
            animation: showDownTop .7s ease-in-out forwards;   
        }

        /* .nopan {
            ::v-deep(.product-list-item) {
                @for $count from 1 to 12 {
                    &:nth-child($count) {
                        animation-delay: calc(0.05s * $count);
                    }
                }
            }
        } */
    }

    &.first {
        margin-bottom: 0;

        &-lang {
            &-ru {
                .first-title {
                    span {
                        line-height: 1.1em;
                        @media (--viewport-sm) {
                            font-size: 58px;
                            font-weight: 700;
                        }
                        @media (--viewport-lg) {
                            font-size: 66px;
                        }
                        @media (--viewport-xl) {
                            font-size: 73px;
                        }

                        &:first-of-type {
                            @media (--viewport-sm-max) {
                                font-size: 62px;
                            }
                        }
                        &:last-of-type {
                            @media (--viewport-sm-max) { 
                                font-size: 54px;
                            }
                        }
                    }
                    &-sub {
                        font-weight: 300;
                        font-family: var(--font-condensed-expanded);
                        font-size: 18px;
                        line-height: 1em;
                        @media (--viewport-sm) {
                            font-size: 19px;
                        }
                        @media (--viewport-md) {
                            font-size: 20px;
                        }
                        @media (--viewport-lg) {
                            font-size: 22px;
                        }
                        @media (--viewport-xl) {
                            font-size: 24px;
                        }
                    }
                }
            }
            &-eng {
                .first-title {

                    span {
                        @media (--viewport-sm) {
                            font-size: 56px;
                        }
                        @media (--viewport-md) {
                            font-size: 64px;
                        }
                        @media (--viewport-lg) {
                            font-size: 73px;
                        }

                        &:first-of-type {
                            @media (--viewport-sm-max) {
                                font-size: 40px;
                            }
                        }
                        &:last-of-type {
                            @media (--viewport-sm-max) {
                                font-size: 70px;            
                            }                
                        }
                    }

                    &-sub {
                        font-size: 15px;
                        @media (--viewport-sm) {
                            font-size: 17px;
                        }
                        @media (--viewport-md) {
                            font-size: 18px;
                        }
                        @media (--viewport-lg) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .first-title {
        display: flex;
        flex-direction: column;
        font-family: var(--font-title-eng);
        color: #fff;
        text-transform: uppercase;
        align-items: center;
        margin-top: 64px;
        margin-bottom: 8px;
        @media (--viewport-sm) {
            display: block;
            text-align: center;
            margin-top: 72px;
        }
        @media (--viewport-md) {
            margin-top: 80px;
        }
        @media (--viewport-lg) {
            margin-top: 96px;
        }

        span {
            font-family: inherit;
            line-height: 1em;
            text-align: center;

            &:first-of-type {
                font-weigth: normal;
            }
            &:last-of-type {
                font-weight: bold;
            }
        }

        &-sub {
            display: block;
            font-weight: 300;
            font-size: 15px;
            color: #fff;
            text-align: center;
            margin-bottom: 56px;
            @media (--viewport-md) {
                margin-bottom: 64px;
            }
            @media (--viewport-lg) {
                margin-bottom: 72px;
            }
            @media (--viewport-xl) {
                margin-bottom: 80px;
            }
        }
    }

    .button {
        width: 240px;
        height: 44px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
        @media (--viewport-sm) {
            display: none;
        }
    }

    .first-container {

        ::v-deep(.contact) {
            margin-left: auto;
            margin-right: auto;
            @media (--viewport-sm) {
                display: none;
            }

            &-num {
                text-align: center;
            }

            &-tel {
                margin-bottom: 4px;
                .contact-num {
                    font-size: 26px;
                }
            }

            &-mailto {
                font-size: 13px;
                font-weight: 300;
            }

            &-icon {
                display: none;
            }
        }

    }

    .product-list {
        margin-top: 72px;
    }
}
</style>
