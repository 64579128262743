/* eslint-disable no-eval */

export default `
Azuma предлагает широкую ассортиментную линейку главных составляющих системы VVT-i:  
* муфта VVT-i;
* электромагнитный клапан (OCV — Oil Control Valve).

При производстве которых используются высококачественные сплавы металлов, обладающие повышенной стойкостью к трению. Это обеспечивает минимальный износ механизма, а также продлевает его срок службы. Муфты и электромагнитные клапаны VVT-i AZUMA обеспечивают бесперебойную и уверенную работы всей системы двигателя.

Особенности и преимущества:
* материалы состоят из высококачественных сплавов, которые обладают повышенной износостойкостью;
* качественная смазка, способствующая минимальному трению механизмов;
* надежная защита и герметичность уплотнений;
* продукция прошла термические испытания.
`
