<template lang="pug">
.form-row(:class="className")
	input.form-row-input(
		v-model="value"
		:type="type"
		:name="name"
		:required="required"
		:id="id"
		:modelValue="value"
		@input="(event) => $emit('update:modelValue', event.target.value)"
	)
	label.form-row-label(:for="id") {{ label }}
</template>

<script>
import { ref, watch } from 'vue'

export default {    
	props: {
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true
		},
		required: {
			type: Boolean,
			required: true
		},
		id: {
			type: String,
			required: true
		}
	},

	setup(props) {
		const value = ref('')
		const className = ref([])
		const valueValidate = ref(null)
        
		const validate = () => {
			let reg = ''
			switch (props.type) {
			case 'tel':
				reg = /^(([0-9]|\+[0-9])[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{10}$/
				return reg.test(value.value)
			default:
				return value.value !== ''
			}
		}

		watch(value, (val) => {
			const valid = validate()
			className.value[1] = val.length > 0 ? 'active' : ''
			className.value[2] = !valid ? 'error' : ''
		})
		
		return {
			value,
			valueValidate,
			validate,
			className
		}
	}
}
</script>

<style lang="postcss" scoped>
.form-row {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    &:hover,
    &:focus,
    &:active,
    &.active {
        label {
            font-size: 10px;
            top: 12px;
        }
    }

    &.error {
        .form-row-input {
            border: 1px solid #E52330;
        }
    }
    
    &-input {
        display: block;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        height: 48px;
        width: 100%;
        padding-left: 16px;
        padding-top: 12px;
        font-family: var(--font-condensed);
        font-weight: 400;
        font-size: 20px;
    }

    &-label {
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        color: #3E3E3F;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition: all .15s ease-in-out;
        left: 16px;
        display: flex;
        align-items: center;
    }
}
</style>
