<template lang="pug">
transition(name="modal")
	.modal-mask(
		v-if="status" @click="changeModalStatus($event)"
		:class="className"
	)
		.modal-container(ref="modal")
			IconClose.modal-close(:id="`close-${id}`")
			.modal-body
				TheModalProduct(v-if="type === 'product'" :id="productId")
				TheModalForm(v-if="type === 'form'" id="modal-form")
</template>

<script>
import {
	ref, computed, onMounted, watch 
} from 'vue'
import { useStore } from 'vuex'
import TheModalProduct from './TheModalProduct.vue'
import TheModalForm from './TheModalForm.vue'
import IconClose from './IconClose.vue'

export default {
	components: {
		TheModalProduct,
		TheModalForm,
		IconClose
	},

	setup() {
		const store = useStore()
		const modal = ref(null)
		const layout = ref('center')
		const status = computed(() => store.state.modal.status)
		const type = computed(() => store.state.modal.type)
		const productId = computed(() => store.state.modal.productId)
		
		const id = computed(() => {
			switch (type.value) {
			case 'form':
				return 'form'
			case 'product':
				return `product-${productId.value}`
			default:
				return '' 
			}
		})
		const className = computed(() => {
			const arr = []
			arr.push(`modal-${store.state.modal.type}`)
			arr.push(`modal-${layout.value}`)
			return arr 
		})

		const changeModalStatus = (e) => {
			if ( 
				e.target.classList.contains('modal-mask')
				|| e.target.classList.contains('modal-close')
				|| e.target.closest('.modal-close')
			) {
				store.commit('changeModalStatus', false)
				store.commit('changeModalType', '')
				store.commit('changeModalProduct', '')
			}
		}

		const setLayout = () => {
			const height = modal.value.clientHeight
			const heightW = window.innerHeight
			const sum = height + 24 * 2
			layout.value = sum > heightW ? 'top' : 'center'
		}

		onMounted(() => {	
			if (status.value) setLayout()
			window.addEventListener('resize', () => {
				if (status.value) setLayout()
			})
		})

		watch(status, (val) => {
			if (val) {
				setTimeout(() => { setLayout() }, 300)
			}
		})
        
		return {
			status,
			changeModalStatus,
			modal,
			layout,
			className,
			type,
			productId,
			id
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	transition: opacity 0.3s ease;
	overflow-y: auto;
	align-items: center;
	justify-content: center;
	transition: opacity 0.3s ease;
	background-color: rgba(0, 0, 0, 0.5);
	@media (--viewport-sm) {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	&.modal-center {
		align-items: center;
	}

	&.modal-top {
		aling-items: flex-start;

		.modal-container {
			margin-top: auto;
		}
	}
}

.modal-close {
	width: 24px;
	height: 24px;
	position: absolute;
	top: 24px;
	right: 24px;
	z-index: 2;
	cursor: pointer;
	transition: all .15s ease-in-out;

	::v-deep {
		&(path) {
			transition: all .15s ease-in-out;
		}
	}

	&:hover {
		transform: scale(1.03);

		::v-deep {
			&(path) {
				fill: #000;
			}
		}
	}
}

.modal-container {
	padding: 0;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
	z-index: 2;
	position: relative;
	@media (--viewport-sm-max) {
		height: 100%;
		width: 100%;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.modal {
	&-product {
		.modal-container {
			@media (--viewport-sm) {
				width: calc(100% - 48px);
				max-width: 500px;
			}
			@media (--viewport-md) {
				max-width: 700px;
			}
			@media (--viewport-lg) {
				max-width: 1000px;
			}
		}
	}

	&-form {
		.modal-container {
			@media (--viewport-sm) {
				width: calc(100% - 48px);
				max-width: 100%;
			}
			@media (--viewport-md) {
				max-width: 700px;
			}
			@media (--viewport-lg) {
				max-width: 830px;
			}
		}
	}
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-enter-active {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

</style>
