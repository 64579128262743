/* eslint-disable no-eval */

export default `
AZUMA chain sets allow a group of valves and pistons to work optimally, ensuring their consistent operation. The timing chain combines the track with the camshaft in a well-coordinated mechanism. All this is created by giving precisely verified details of the set. The gears are made of high-quality quenching metal. The chain tensioner has silicone gaskets at the rods. The chock? are made of MBS plastic and aluminum, which facilitates the construction


Special Features and benefits:

* exceptional quality;
* designed for a run of more than 200 thousand km with proper oil regulation;
* the circuit is resistant to local motor overloads;
* the parts are made of light but strong materials.
`
