/* eslint-disable no-eval */

export default `
Ремкомплект (Full gasket set) двигателя(engine) AZUMA включает в себя набор прокладок, сальники, маслосъемные колпачки, манжеты, уплотнительные резинки. Ремкомплект изготовлен по оригинальным лекалам. 

Обеспечивает надежную работу узлов двигателя. Благодаря специальному составу резины прокладки и сальники защищены от растрескивания и коррозии на длительное время. Асбестовые и паранитовые изделия изготовлены из материалов высшей пробы, что придает им отличную жаропрочность и упругость. Сальники отлично защищают от попадания грязи вовнутрь двигателя. Маслосъемные колпачки не уступают своим качеством ведущим японским производителям.

Особенности и преимущества:

* устойчив к перепадам температур;
* обеспечивает 100% герметичность;
* имеет большой ресурс;
* выдерживает жесткий режим эксплуатации двигателя.
`
