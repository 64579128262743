<template lang="pug">
.form-row
    input.form-row-input(
        v-model="value"
        type="checkbox"
        :name="name"
        :required="required"
        :id="id"
        :modelValue="value"
		@change="(event) => $emit('update:modelValue', event.target.value)"
    )
    label.form-row-label(
        :for="id" 
        :class="className"
    ) 
        .form-row-checkbox
        p.form-row-text {{ label }}
</template>

<script>
import { ref, computed, watch } from 'vue'

export default {    
	props: {
		label: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		required: {
			type: Boolean,
			required: true
		},
		id: {
			type: String,
			required: true
		},
		checked: {
			type: Boolean,
			required: true
		},
	},

	setup(props) {
		const value = ref(false)
		const error = ref(false)
		const checked = computed(() => props.checked)
		const className = computed(() => (error.value ? ['form-row-error'] : ''))
		if (props.checked) value.value = checked.value

		watch(value, (val) => {
			error.value = props.required && !val
		}) 

		return {
			value,
			className,
			error
		}
	}
}
</script>

<style lang="postcss" scoped>
.form-row {
    display: flex;
    align-items: center;

    .form-row {

        &-error {
            .form-row-checkbox {
                border: 1px solid #E52330;
            }
            .form-row-text {
                color: #E52330;
                font-weight: bold;
            }
        }

        &-input {
            display: none;

            &:checked {

                ~ .form-row-label {
                    
                    .form-row-checkbox {
                        border: 1px solid transparent;

                        &:after,
                        &:before {
                            transform: scale(1) translate3d(-50%, -50%, 0);
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #C8C5C5;
            border-radius: 3px;
            width: 18px;
            height: 18px;
            flex: 0 0 18px;
            position: relative;
            cursor: pointer;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: scale(.5) translate3d(-50%, -50%, 0);
                opacity: 0;
                transition: all .3s ease-in-out;
                transform-origin: 0 0;
            }

            &:before {
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                border-radius: 3px;
                background: radial-gradient(100% 132.54% at 0% 50%, #D90303 0%, #F05858 57.43%, #DA0202 100%);
            }

            &:after {
                width: 100%;
                height: 100%;
                background: url('../assets/img/icon-checkbox.svg') center no-repeat;
            }
        }

        &-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            max-width: 260px;
        }

        &-text {
            font-size: 13px;
            font-weight: 400;
            margin-left: 16px;
        }
    }
}
</style>
