<template lang="pug">
router-view
</template>

<script>
import 'normalize.css'
import { useStore } from 'vuex'
import {
	computed, watch, onBeforeMount 
} from 'vue'
import Cookies from 'js-cookie'

export default {
	name: 'App',

	setup() {
		const store = useStore()
		const modalStatus = computed(() => store.state.modal.status)
		
		const cookieLang = Cookies.get('lang')
		if (cookieLang) store.commit('changeLang', cookieLang)
		
		const metaTitle = computed(() => store.state.meta.title[store.state.lang])
		const metaDescription = computed(() => store.state.meta.description[store.state.lang])

		watch(modalStatus, (val) => {
			if (val) {
				document.body.classList.add('hidden')
			} else {
				document.body.classList.remove('hidden')
			}
		})

		watch(metaTitle, (val) => { document.title = val })
		watch(metaDescription, (val) => { document.querySelector('meta[name=description]').setAttribute('content', val) })

		onBeforeMount(() => {
			document.title = metaTitle.value
			document.querySelector('meta[name=description]').setAttribute('content', metaDescription.value)
		})
		
		return {
			modalStatus,
		}
	}
}
</script>

<style lang="postcss">
@import './assets/css/vendor.css';

.fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	background: #000;
	span {
		color: #fff;
		margin-right: 40px;
	}
}
</style>
