import { useStore } from 'vuex'
import { computed } from 'vue'
import { dataNavTab, dataFirstTitle } from './TheFirstData'

export const getFirstContent = () => {
	const store = useStore()
	const lang = computed(() => store.state.lang)

	const title = computed(() => ({
		title: dataFirstTitle.title[lang.value],
		titleSub: dataFirstTitle.titleSub[lang.value]
	}))

	return {
		title
	}
}

export const getNavTab = () => {
	const store = useStore()
	const lang = computed(() => store.state.lang)

	const tabs = computed(() => dataNavTab.map((el) => {
		const data = { ...el }
		data.name = data.name[lang.value]
		return data
	}))

	return {
		tabs
	}
}
