<template lang="pug">
.product(
    @click="changeModalStatus" 
    :class="className" 
)       
    Logo(:id="`product-${data.id}`")
    .product-image(:style="style")
    .product-content
        span.product-title {{ title }}
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import Logo from './IconLogotype.vue'

export default {
	components: {
		Logo
	},

	props: {
		data: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		}
	},

	setup(props) {
		const store = useStore()
		const title = computed(() => props.data.title[store.state.lang])
		const className = ref([])
		const style = ref({})
		const changeModalStatus = () => {
			store.commit('changeModalStatus', true)
			store.commit('changeModalType', 'product')
			store.commit('changeModalProduct', props.data.id)
		}
        
		let classNameFirst = 'bottom'
		if (props.index % 2 === 0) classNameFirst = 'top'
		className.value.push(classNameFirst)

		if (props.data.img.slider) style.value.background = `url(${props.data.img.slider}) center / auto 133% no-repeat`

		return {
			title,
			changeModalStatus,
			className,
			style
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/animation.css';

.product {
    display: flex;
    flex-shrink: 0;
    width: 240px;
    height: 390px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -10px 30px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: all .3s ease-in-out;

    .logotype {
        transform: rotate(90deg) translate3d(0, 105%, 0);
        position: absolute;
        left: 0;
        top: calc(90px / 2 - 100px);
        width: 90px;
        z-index: 2;
        opacity: 0;
        transition: all .3s ease-in-out;

        ::v-deep(path) {
            fill: rgb(229, 35, 48);
        }
    }

    &:hover {
        .product-image {
            transition: all 5s;
            transform: scale(1.3) translate3d(10%,0,0);
        }
        .logotype {
            top: calc(90px / 2 - 16px);
            opacity: 1;
        }
    }

    &.top {
        .product-image { order: 1; }
        .product-content { 
            order: 0;
            margin-bottom: auto; 
        }
    }

    &.bottom {
        .product-image { order: 0; }
        .product-content { 
            order: 1; 
            margin-top: auto;
        }
    }

    &-content {
        padding-right: 16px;
        padding-left: 16px;
        margin-left: auto;
    }

    &-image {
        order: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all .5s;
    }

    &-title {
        position: relative;
        display: block;
        font-size: 1.125rem;
        font-weight: 700;
        font-family: var(--font-condensed);
        text-transform: uppercase;
        color: rgb(71, 71, 71);
        width: auto;
        padding-top: 16px;
        padding-bottom: 16px;

        &:before {
            content: "";
            background: radial-gradient(100% 576% at 100% 0px, rgba(0, 54, 116, 0.96) 0px, rgba(0, 49, 106, 0.96) 100%);
            width: calc(100% + 24px);
            height: 3px;
            right: -16px;
            top: 0px;
            display: block;
            position: absolute;
        }
    }
}
</style>
