<template lang="pug">
section#about.banner(:class="`banner-${lang}`")
    .container
        .banner-left
            Logo(id="banner")
            ul.banner-list
                li.banner-list-item(v-for="item in itemsArray") 
                    span.banner-list-title {{ item.title[lang] }}
                    span.banner-list-text {{ item.text[lang] }}
            p.banner-left-text {{ text[lang] }}
            span.banner-quality.banner-separate {{ textQuality[lang] }}
        .banner-right   
            ul.banner-right-list
                li.banner-right-item(v-for="item in itemsArraySecond") {{ item[lang] }}
            span.banner-quality {{ textQuality[lang] }}
            p.banner-right-title {{ textDown.title[lang] }}
                span.banner-right-text {{ textDown.titleSub[lang] }}
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Logo from './IconLogotype.vue'

export default {
	components: {
		Logo
	},

	setup() {
		const store = useStore()
		const lang = computed(() => store.state.lang)
		const itemsArray = [
			{
				title: {
					eng: 'One Brand',
					ru: 'Одна марка'
				},
				text: {
					eng: 'One quality',
					ru: 'Одно качество',
				}
			},
			{
				title: {
					eng: 'Advanced',
					ru: 'Новейшие'
				},
				text: {
					eng: 'Technology',
					ru: 'Технологии',
				}
			},
			{
				title: {
					eng: 'Product warranty',
					ru: 'Гарантия на продукцию'
				},
				text: {
					eng: '2 years',
					ru: '2 года',
				}
			}
		]

		const itemsArraySecond = [
			{
				eng: 'wide range',
				ru: 'широкий ассортимент',
			},
			{
				eng: 'innovative technology',
				ru: 'инновационные технологии'
			},
			{
				eng: 'lean production',
				ru: 'бережливое производство'
			},
			{
				eng: 'caring for the environment',
				ru: 'забота о экологии'
			}
		]

		const text = {
			eng: 'Azuma combines various industries and brings products to a qualitatively different level.',
			ru: 'Азума объединяет различные производства и выводит продукцию на качественно другой уровень'
		}

		const textDown = {
			title: {
				eng: 'Japanese',
				ru: 'Японские'
			},
			titleSub: {
				eng: 'Technology',
				ru: 'Технологии'
			}
		}

		const textQuality = {
			eng: 'Hight Quality',
			ru: 'Высокое качество'
		}

		return {
			itemsArray,
			itemsArraySecond,
			lang,
			text,
			textDown,
			textQuality
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.banner {
    --banner-padding: 80px;
    --banner-left: 100%;
    --banner-right: 100%;
    background: url('../assets/img/background-banner.jpg') center / cover no-repeat rgb(0, 54, 116);
    @media (--viewport-md) {
        --banner-padding: 140px;
        --banner-left: 50%;
        --banner-right: calc(100% - var(--banner-left));
    }
    @media (--viewport-lg) {
        --banner-left: 40%;
    }
    @media (--viewport-xl) {
        --banner-left: 34%;
    }

    .container {
        display: flex;
        flex-direction: column;
        @media (--viewport-md) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .logotype {
        width: 106px;
        @media (--viewport-md) {
            width: 134px;
            margin-bottom: 40px;
        }
        @media (--viewport-xl) {
            margin-bottom: 48px;
        }
    }

    &-quality {
        display: none;
        color: #fff;
        text-transform: uppercase;
        @media (--viewport-md) {
            display: block; 
            font-size: 80px;
            font-weight: 600; 
            margin-bottom: 24px; 
            line-height: 1em;         
        }
        @media (--viewport-xl) {
            font-size: 93px;
            margin-bottom: 32px;
            white-space: nowrap;
        }
    }

    &-ru {
        .banner-quality { 
            font-family: var(--font-condensed); 
        }
    }

    &-eng {
        .banner-quality { 
            font-family: var(--font-title-eng); 
        }
    }

    &-list {
        padding-left: 0;
        margin-bottom: 32px;
        margin-top: 0;
        @media (--viewport-md) {
            margin-bottom: 40px;
        }
        @media (--viewport-xl) {
            margin-bottom: 48px;
        }

        &-title {
            font-weight: 600;
            font-size: 26px;
            @media (--viewport-md) {
                font-size: 30px;
            }
        }

        &-text {
            font-size: 17px;
            padding-left: 6px;
            @media (--viewport-md) {
                font-size: 20px;
            }
        }

        &-item {
            list-style-type: none;
            text-transform: uppercase;
            margin-bottom: 0;

            &:last-of-type {
                margin-bottom: 0;
            }

            span {
                font-family: var(--font-condensed);
                color: #fff;
            }
        }
    }

    .banner-separate {
        display: flex;
        writing-mode: vertical-lr;
        white-space: nowrap;
        transform: rotate(-180deg);
        position: absolute;
        left: -154px;
        top: 0;
        font-size: 140px;
        color: rgba(255, 255, 255, .04);
        width: auto;
        @media (--viewport-md) {
            font-size: 130px;
            left: auto;
            right: 0;
        }
        @media (--viewport-lg) {
            font-size: 115px;
        }
    }

    &-left {
        position: relative;
        width: var(--banner-left);
        padding-top: var(--banner-padding);
        @media (--viewport-md) {
            margin-right: auto;
            padding-bottom: var(--banner-padding);
            border-right: 1px solid rgba(255, 255, 255, .1);
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        &-text {
            font-size: 14px;
            font-weight: 300;
            color: #fff;
            line-height: 1.6em;
            margin-bottom: 32px;
            max-width: 240px;
            @media (--viewport-md) {
                font-size: 15px;
                margin-bottom: 0;
            }
        }
    }

    &-right {
        position: relative;
        width: var(--banner-right);
        padding-bottom: var(--banner-padding);
        @media (--viewport-md) {
            margin-left: auto;
            padding-left: 80px;
            padding-right: 32px;
            padding-top: var(--banner-padding);
        }
        @media (--viewport-xl) {
            padding-left: 240px;
        }

        &:before {
            content: '';
            background: url('../assets/img/banner-separate.png') center / auto 100% no-repeat;
            position: absolute;
            top: -320px;
            right: -32px;
            width: 300px;
            height: 600px;
            display: block;
            transform: scale(-1, 1);
            @media (--viewport-md) {
                right: auto;
                left: 0;
                transform: none;
                top: 0;
                background-position: 100% auto;
                height: 100%;
            }
            @media (--viewport-xl) {
                width: 500px;
            }
        }

        &-list {
            padding-left: 0;
            margin-bottom: 32px;
            margin-top: 0;
            @media (--viewport-md) {
                margin-bottom: 40px;
            }
        }

        &-title {
            display: flex;
            flex-direction: column;
            font-size: 40px;
            font-weight: 600;
            font-family: var(--font-condensed);
            text-transform: uppercase;
            line-height: 1em;
            color: #fff;
            @media (--viewport-md) {
                font-size: 51px;
            }
        }

        &-text {
            font-size: 31px;
            font-weight: 300;
            font-family: var(--font-condensed);
            text-transform: uppercase;
            color: #fff;
            @media (--viewport-md) {
                font-size: 39px;
            }
        }

        &-item {
            list-style-type: none;
            text-transform: uppercase;
            font-family: var(--font-condensed);
            font-size: 17px;
            color: #fff;
            margin-bottom: 4px;
        }
    }
}
</style>
