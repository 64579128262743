<template lang="pug">  
IconBase.logotype(
    iconName="Logotype icon"
    viewBox="0 0 82 32"
)
    path(d='M30.6018 5.40153L29.4102 3.27328H16.9901V6.24785H26.6215L16.4373 20.237L9.86482 3.27328H7.55525L0 22.4524H3.32922L5.41767 17.0384H12.0638L14.054 22.3777H30.4421L30.479 19.8014H20.1719L30.6018 5.40153ZM6.47417 14.2879L8.79603 8.26409L11.0319 14.2879H6.47417Z' fill='white')
    path(d='M42.4323 3.28571V19.316C41.3635 20.0503 39.3856 20.1499 38.5625 20.1623C38.5625 20.1623 38.4765 20.1623 38.3291 20.1623C38.2431 20.1623 38.194 20.1623 38.194 20.1623C37.3831 20.1499 35.393 20.0503 34.3119 19.316V3.28571L31.5478 3.26082V21.8176C31.5478 21.8176 33.9065 22.9253 38.194 22.9876V23C38.2554 23 38.3168 22.9876 38.3782 22.9876C38.4397 22.9876 38.4888 23 38.5625 23V22.9876C42.85 22.9253 45.2087 21.8176 45.2087 21.8176V3.27327L42.4323 3.28571ZM38.4028 1.92911L39.2259 2.70076H42.6411L39.7787 0H36.9532L34.2873 2.75054H37.4691L38.4028 1.92911Z' fill='white')
    path(d='M61.302 3.50979L55.1349 12.2966L49.017 3.50979H46.818V22.44H49.6435V9.68295L54.4101 16.7273H56.0194L60.7491 9.34691V22.44H63.587V3.50979H61.302Z' fill='white')
    path(d='M77.7146 22.4524H81.1053L73.3166 3.22353H71.351L63.6606 22.4524H66.9776L69.0169 17.0385H75.663L77.7146 22.4524ZM70.098 14.1635L72.3338 8.23922L74.582 14.1635H70.098Z' fill='white')
</template>

<script>
import IconBase from './IconBase.vue'

export default {
	components: {
		IconBase
	},

	props: {
		id: {
			type: String,
			required: true
		}
	}, 
}
</script>

<style>

</style>
