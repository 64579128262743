<template lang="pug">
section.gallery
    .gallery-item(v-for="(image, index) in images" @click="openLightbox(index)")
        img(:src="image")
</template>

<script>
import { computed } from 'vue'
import 'fslightbox'

export default {
	props: {
		images: {
			type: Array,
			required: true
		}
	},

	setup(props) {
		const lightbox = new FsLightbox()
		const images = computed(() => props.images)
		lightbox.props.sources = images.value
        
		const openLightbox = (i) => { 
			lightbox.open(i) 
		}

		return {
			openLightbox
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &-item {
        --gallery-height-all: 48px;
        margin-bottom: 16px;
        background: rgba(0,0,0,.1);
        width: calc((100% - 16px * 3) / 4);
        height: var(--gallery-height-all);
        position: relative;
        cursor: pointer;
        overflow: hidden;
        @media (--viewport-sm) {
            --gallery-height-all: 72px;
        }
        @media (--viewport-md) {
            --gallery-height-all: 84px;
        }
        @media (--viewport-lg) {
            --gallery-height-all: 64px;
        }
        @media (--viewport-xl) {
            --gallery-height-all: 96px;
        }

        &:hover {
            img {
                transition: transform 1s;
                transform: scale(1.05) translate3d(0,0,0);
            }
        }

        img {
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: transform .3s ease-in-out;
            transform-origin: 0 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
            --gallery-height: 140px;
            height: var(--gallery-height);
            width: calc(50% - 8px);
            @media (--viewport-sm) {
                --gallery-height: 200px;
            }
            @media (--viewport-md) {
                --gallery-height: 220px;
            }
            @media (--viewport-lg) {
                --gallery-height: 260px;
            }
            @media (--viewport-xl) {
                --gallery-height: 320px;
            }
        }

        &:nth-child(3) {
            --gallery-height-3: 120px;
            width: 100%;
            height: var(--gallery-height-3);
            @media (--viewport-sm) {
                --gallery-height-3: 160px;
            }
            @media (--viewport-md) {
                --gallery-height-3: 140px;
            }
            @media (--viewport-lg) {
                --gallery-height-3: 160px;
            }
            @media (--viewport-xl) {
                --gallery-height-3: 184px;
            }
        }

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
            margin-bottom: 0;
        }
    }
}
</style>
