/* eslint-disable no-eval */

export default `
Щетки AZUMA для стеклоочистителей сочетают в себе высокую функциональность, современный дизайн, практичность и способны гораздо лучше справляться с очисткой лобовых стекол автомобилей от различных загрязнений и атмосферных осадков.

Особенности и преимущества:
* Повышенный срок службы конструкции;
* Антикоррозийное покрытие каркаса щетки;
* Специальное покрытие лезвия ANTI-Static Coating не накапливает статический заряд на лобовом стекле и создают водоотталкивающий слой.
`
