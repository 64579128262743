<template lang="pug">
transition(name="fixed")
	TheHeader.fixed(v-if="scroll > 500")
</template>

<script>
import { ref } from 'vue'
import TheHeader from './TheHeader.vue'

export default {
	components: {
		TheHeader
	},

	setup() {
		try {
			const scroll = ref(window.pageYOffset)
			window.addEventListener('scroll', () => {
				scroll.value = window.pageYOffset
			})

			return {
				scroll
			}
		} catch (err) {
			console.log(err)
			throw err
		}
	}
}   
</script>

<style lang="postcss" scoped>
@import '../assets/css/animation.css';
@import '../assets/css/media.css';

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
	height: 72px;
    background: rgba(0, 54, 116, 0.9);
	z-index: 1;
	padding-top: 0;
	@media (--viewport-md-max) {
		display: none;
	}

	&-enter-active {
		animation: showTopDownMax .3s ease-in-out both;
	}

	&-leave-active {
		animation: showDownTopMax .3s ease-in-out both;
	}

	::v-deep {
		.logotype {
			@media (--viewport-md-lg) {
				display: none;
			}
		}

		.container {
			border-bottom: 0;
			justify-content: center;
		}

		.header-button {
			@media (--viewport-lg-max) {
				display: none;
			}	

			.button {
				@media (--viewport-lg-max) {
					padding-left: 24px;
					padding-right: 24px;
				}
			}
		}

		.contact {
			&-num {
				margin-right: 0;
			}
		}
	}
}
</style>
