<template lang="pug">
.slider-section(ref="slider")
    section.slider(
        :style="style"
        :class="classes"
    )
        slot
    .slider-pagination.container(v-if="navigation")
</template>

<script scoped>
import Hammer from 'hammerjs'
import { ref, computed, onMounted } from 'vue'

export default {
	components: {
	},

	props: {
		navigation: { 
			type: Boolean, 
			required: false 
		},
		layout: { 
			type: String, 
			required: false 
		},
		activeSlideInit: { 
			type: Number, 
			required: false 
		},
		slideGroup: { 
			type: Number, 
			required: false 
		}
	},

	setup(props, { emit }) {
		const sensitivity = ref(25)
		const activeSlide = ref(0)
		const slideCount = ref(0)
		const marginLeft = ref(0)
		const marginLeftItem = ref(0)
		const marginLeftPx = ref(0)
		const style = ref({})
		const classAnimation = ref(false)
		const classHammer = ref(false)
		const slider = ref(null)
		const timer = ref()

		const classes = computed(() => {
			const arr = []
			if (classAnimation.value) arr.push('is-animating')
			if (!classHammer.value) arr.push('slider-no') 
			return arr
		})

		const getMarginLeft = () => {
			const sliderItems = slider.value.querySelectorAll('.slider-item')
			const windowWidth = window.innerWidth
			
			let itemFirstWidth = 0
			if (sliderItems.length > 0) itemFirstWidth = sliderItems[0].clientWidth + marginLeftItem.value

			const marginLeftValue = windowWidth - itemFirstWidth
			return marginLeftValue / 2
		}

		const setItems = () => {
			const sliderItems = slider.value.querySelectorAll('.slider-item')
			if (sliderItems.length > 0) {
				slideCount.value = sliderItems.length
				marginLeftItem.value = parseFloat(getComputedStyle(sliderItems[0]).marginLeft)
				style.value.width = `${sliderItems[0].clientWidth * slideCount.value + marginLeftItem.value * (slideCount.value * 2)}px`
			}
		}

		const calcItemLeft = () => {
			const items = slider.value.querySelectorAll('.slider-item')
			if (!items) return null

			const itemLast = items[items.length - 1]
			const windowW = window.innerWidth
			switch (props.layout) {
			case 'left':
				return windowW - itemLast.clientWidth - marginLeftItem.value * (activeSlide.value - 1) * 2
			case 'center':
				if (marginLeftPx.value === 0) {
					return -marginLeft.value + (windowW - itemLast.clientWidth) / 2
				}
				return -marginLeft.value + (windowW - itemLast.clientWidth) / 2 - marginLeftItem.value
			default:
				return 0
			}
		}

		const calcItemLeftCenter = () => {
			if (marginLeftPx.value === 0) return 0
			return -marginLeftItem.value / 2
		}

		const goTo = (number) => {
			const items = slider.value.querySelectorAll('.slider-item')

			if (number < 0) {
				activeSlide.value = 0
			} else if (number > slideCount.value - 1) {
				activeSlide.value = slideCount.value - 1
			} else {
				activeSlide.value = number
			}

			classAnimation.value = true

			let percentage
			if (activeSlide.value === 0) {
				percentage = -(100 / slideCount.value) * activeSlide.value
				const itemLeft = calcItemLeftCenter()
				style.value.transform = `translate3d(calc(${percentage}% + ${itemLeft}px), 0, 0)`
			} else if (activeSlide.value === slideCount.value - 1) {
				if (items) {
					percentage = -(100 / slideCount.value) * activeSlide.value
					style.value.transform = `translate3d(${percentage}%, 0, 0)`
				} else {
					console.log('Slider.vue - No items in slider')
				}
			} else if (items) {
				let itemLeft
				if (props.layout === 'left') {
					itemLeft = marginLeftPx.value
					if (marginLeftItem.value) itemLeft = marginLeftPx.value - marginLeftItem.value
				} else {
					itemLeft = calcItemLeftCenter()
				}

				percentage = -(100 / slideCount.value) * activeSlide.value
				style.value.transform = `translate3d(calc(${percentage}% + ${itemLeft}px), 0, 0)`
			} else {
				console.log('Slider.vue - No items in slider')
			}

			emit('slide', activeSlide.value)

			clearTimeout(timer.value)
			timer.value = setTimeout(() => {
				classAnimation.value = false
			}, 400) 
		}

		const slidePrev = () => {
			if (activeSlide.value === 0) return
			goTo(activeSlide.value - 1)
		}

		const slideNext = () => {
			if (activeSlide.value === slideCount.value - 1) return
			goTo(activeSlide.value + 1)
		}

		const setMarginLeft = () => {
			setItems()
			classHammer.value = window.innerWidth < parseFloat(style.value.width)
			marginLeft.value = props.layout === 'left' ? 0 : getMarginLeft()
			marginLeftPx.value = getMarginLeft()
			style.value.marginLeft = `${marginLeft.value}px`
			style.value.marginRight = `${marginLeft.value}px`
		}

		const slideNumPlus = () => {
			const sum = activeSlide.value + props.slideGroup
			return sum > slideCount.value ? slideCount.value : sum
		}
		const slideNumMinus = () => {
			const sum = activeSlide.value - props.slideGroup
			return sum < 0 ? 0 : sum
		}

		const sliderInit = () => {
			const sliderManager = new Hammer(slider.value)
			sliderManager.on('pan', (e) => {
				classHammer.value = window.innerWidth < parseFloat(style.value.width)
				if (classHammer.value) {
					const percentage = ((100 / slideCount.value) * e.deltaX) / window.innerWidth
					let percentageCalculated = percentage - (100 / slideCount.value) * activeSlide.value
					let itemLeft = 0

					if (props.layout === 'left') {
						if (activeSlide.value !== slideCount.value - 1 && activeSlide.value !== 0) {
							const marginLeftPercent = (marginLeftPx.value / parseFloat(style.value.width)) * 100
							percentageCalculated += marginLeftPercent
						} else if (activeSlide.value === slideCount.value - 1) {
							const marginLeftPercent = (calcItemLeft() / parseFloat(style.value.width)) * 100
							percentageCalculated += marginLeftPercent
						}
					} else {
						itemLeft = calcItemLeftCenter()
					}

					style.value.transform = `translate3d(calc(${percentageCalculated}% + ${itemLeft}px), 0, 0)`

					let slideNum

					if (e.isFinal) {
						if (e.velocityX > 1) {
							slideNum = slideNumMinus()
						} else if (e.velocityX < -1) {
							slideNum = slideNumPlus()
						} else if (percentage <= -(sensitivity.value / slideCount.value)) {
							slideNum = slideNumPlus()
						} else if (percentage >= (sensitivity.value / slideCount.value)) {
							slideNum = slideNumMinus()
						} else {
							slideNum = activeSlide.value 
						}
						goTo(slideNum)
					}
				} else {
					style.value.transform = 'translate3d(0,0,0)'
				}
			})
		}

		onMounted(() => {
			window.addEventListener('load', () => {
				setMarginLeft()
				sliderInit()
				window.addEventListener('resize', () => {
					goTo(activeSlide.value)
					setMarginLeft()
				})

				if (props.activeSlideInit) goTo(props.activeSlideInit)
			})
		})

		return {
			// data
			sensitivity,
			activeSlide,
			slideCount,
			marginLeft,
			marginLeftItem,
			marginLeftPx,
			style,
			classAnimation,
			classHammer,
			slider,
			classes,

			// Methods
			getMarginLeft,
			setMarginLeft,
			setItems,
			goTo,
			slidePrev,
			slideNext,
			calcItemLeft,
			calcItemLeftCenter,
			sliderInit
		}
	}
}
</script>

<style lang="postcss" scoped>
.slider {
    display: flex;
    position: relative;

    &-section {
        display: flex;
        flex-direction: column;
    }

    &-pagination {
        display: flex;
        justify-content: space-between;
        position: relative;

        &-nav {
            position: absolute;
            top: -48px;
            z-index: 0;
        }

        &-prev {
            left: 0;
            padding: 8px;
            width: calc(33px + 16px);
            height: calc(12px + 16px);
        }

        &-next {
            right: 0;
            padding: 8px;
            width: calc(33px + 16px);
            height: calc(12px + 16px);
            transform: scale(-1, 1);
        }
    }

    &-no {
        transform: translate3d(0,0,0) !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 100% !important;
        max-width: calc(100% - 40px) !important;
        justify-content: center;
        @media (width >= 1200px) {
            max-width: 1200px !important;
        }
    }

    &.is-animating {
        transition: transform .3s ease-in-out;
    }
}
</style>
