import { createStore, createLogger } from 'vuex'
import Cookies from 'js-cookie'
import productFGSEng from './content/product-fgs-eng'
import productFGSRu from './content/product-fgs-ru'
import productAirEng from './content/product-air-eng'
import productAirRu from './content/product-air-ru'
import productATMeng from './content/product-atm-eng'
import productATMru from './content/product-atm-ru'
import productBrakeDiskEng from './content/product-brakedisk-eng'
import productBrakeDiskRu from './content/product-brakedisk-ru'
import productBrakePadEng from './content/product-brakepad-eng'
import productBrakePadRu from './content/product-brakepad-ru'
import productBrakeShoesEng from './content/product-brakeshoes-eng'
import productBrakeShoesRu from './content/product-brakeshoes-ru'
import productChainEng from './content/product-chain-eng'
import productChainRu from './content/product-chain-ru'
import productClutchEng from './content/product-clutch-eng'
import productClutchRu from './content/product-clutch-ru'
import productGearEng from './content/product-gear-eng'
import productGearRu from './content/product-gear-ru'
import productOilRu from './content/product-oil-ru'
import productOilEng from './content/product-oil-eng'
import productWiperRu from './content/product-wiper-ru'
import productWiperEng from './content/product-wiper-eng'

export default createStore({
	state: {
		lang: 'eng',
		dataButton: {
			ru: 'Связаться с нами',
			eng: 'Contact Us'
		},
		dataButtonSuccess: {
			ru: 'Заявка отправлена',
			eng: 'Request has been received'
		},
		modal: {
			status: false,
			type: '',
			productId: ''
		},
		meta: {
			title: {
				eng: 'Azuma - Korean spare parts for Japanese cars',
				ru: 'Azuma - Корейские запчасти для японских автомобилей'
			},
			description: {
				eng: 'Azuma - Korean spare parts for Japanese cars. Azuma combines various industries and brings products to a qualitatively different level.',
				ru: 'Azuma - Корейские запчасти для японских автомобилей. Азума объединяет различные производства и выводит продукцию на качественно другой уровень.'
			}
		},
		contacts: {
			phones: [
				{ 
					type: 'main', 
					name: '8 (800) 100-64-64',
					link: 'tel',
					text: {
						eng: 'Sales department',
						ru: 'Отдел продаж'
					}
				},
				{
					type: 'modal',
					name: '+7 (423) 260 49 63',
					link: 'tel',
					text: {
						eng: 'Sales department',
						ru: 'Отдел продаж'
					}
				},
				{
					type: 'modal',
					name: '+7 (423) 260-49-65',
					link: 'tel',
					text: {
						eng: 'Sales department',
						ru: 'Отдел продаж'
					}
				}
			],
			email: [
				{ 
					type: 'main', 
					name: 'azuma@azuma-parts.com',
					link: 'mailto',
					text: {
						eng: 'Sales department',
						ru: 'Отдел продаж'
					}
				}
			],
			address: [
				{
					country: {
						eng: 'South Korea',
						ru: 'Южная Корея'
					},
					address: {
						eng: 'Seoul, Jongno-gu district, st. Sachzhik-ro 161',
						ru: 'Сеул, Джонго район, ул. Шацзик , д. 61'
					}
				},  
				{
					country: {
						eng: 'Russian Federation',
						ru: 'Российская Федерация'
					},
					address: {
						eng: 'Primorsky kr., Vladivostok, st. Ilyicheva, 29 office 35',
						ru: 'Приморский край, г. Ильичева 29, офис 375'
					}
				}
			]
		},
		products: [
			{
				layout: {
					main: true,
					slider: true
				},
				id: 'airFilters',
				title: {
					eng: 'Air Filters',
					ru: 'Воздушные фильтры',
				},
				content: {
					eng: productAirEng,
					ru: productAirRu
				},
				icon: 'iconAirFilter',
				img: {
					slider: require('../assets/img/product-air-slider.jpg'),
					modal: require('../assets/img/product-air-modal.png')
				}
			},
			{
				layout: {
					main: true,
					slider: true
				},
				id: 'ATMFilters',
				title: {
					eng: 'ATM Filters',
					ru: 'Фильтры рабочей жидкости',
				},
				content: {
					eng: productATMeng,
					ru: productATMru
				},
				icon: 'IconATMFilters',
				img: {
					slider: null,
					modal: null
				}
			},
			{
				layout: {
					main: true,
					slider: true
				},
				id: 'wiperBladeWipers',
				title: {
					eng: 'Wiper Blade Wipers',
					ru: 'Дворники',
				},
				content: {
					eng: productWiperEng,
					ru: productWiperRu
				},
				icon: 'IconWiper',
				img: {
					slider: require('../assets/img/product-wiper-slider.jpg'),
					modal: require('../assets/img/product-wiper-modal.png')
				}
			},
			{
				layout: {
					main: true,
					slider: true
				},
				id: 'chainTimingKit',
				title: {
					eng: 'Chain Timing Kit',
					ru: 'Цепные наборы',
				},
				content: {
					eng: productChainEng,
					ru: productChainRu
				},
				icon: 'IconChainTimingKit',
				img: {
					slider: require('../assets/img/product-chaintimingkit-slider.jpg'),
					modal: require('../assets/img/product-chaintimingkit-modal.png')
				}
			},
			{
				layout: {
					main: true,
					slider: true
				},
				id: 'brakeDisk',
				title: {
					eng: 'Brake Disk',
					ru: 'Тормозные диски',
				},
				content: {
					eng: productBrakeDiskEng,
					ru: productBrakeDiskRu
				},
				icon: 'IconBrakeDisk',
				img: {
					slider: require('../assets/img/product-brakedisk-slider.jpg'),
					modal: require('../assets/img/product-brakedisk-modal.png')
				}
			},
			{
				layout: {
					main: true,
					slider: true
				},
				id: 'brakeShoes',
				title: {
					eng: 'Brake Shoes',
					ru: 'Тормозные колодки',
				},
				content: {
					eng: productBrakeShoesEng,
					ru: productBrakeShoesRu
				},
				icon: 'IconBrakeShoes',
				img: {
					slider: require('../assets/img/product-brakeshoes-slider.jpg'),
					modal: require('../assets/img/product-brakeshoes-modal.png')
				}
			},
			{
				layout: {
					main: false,
					slider: false
				},
				id: 'brakePads',
				title: {
					eng: 'Brake Pads',
					ru: '',
				},
				content: {
					eng: productBrakePadEng,
					ru: productBrakePadRu
				},
				icon: 'IconBrakePads',
				img: {
					slider: require('../assets/img/product-brakepad-slider.jpg'),
					modal: require('../assets/img/product-brakepad-modal.png')
				}
			},
			{
				layout: {
					main: false,
					slider: false
				},
				id: 'oil',
				title: {
					eng: 'Oil Filters',
					ru: 'Масляные фильтры',
				},
				content: {
					eng: productOilEng,
					ru: productOilRu
				},
				icon: 'IconBrakeShoes',
				img: {
					slider: require('../assets/img/product-oil-slider.jpg'),
					modal: require('../assets/img/product-oil-modal.png')
				}
			},
			{
				layout: {
					main: false,
					slider: false
				},
				id: 'gear',
				title: {
					eng: 'Gear Camshaft Timing Couplings',
					ru: '',
				},
				content: {
					eng: productGearEng,
					ru: productGearRu
				},
				icon: '',
				img: {
					slider: null,
					modal: null
				}
			},
			{
				layout: {
					main: true,
					slider: false
				},
				id: 'clutch',
				title: {
					eng: 'Clutch',
					ru: 'Муфты',
				},
				content: {
					eng: productClutchEng,
					ru: productClutchRu
				},
				icon: 'IconFunClutch',
				img: {
					slider: null,
					modal: null
				}
			},
			{
				layout: {
					main: true,
					slider: false
				},
				id: 'fullGasketSet',
				title: {
					eng: 'Full Gasket Set',
					ru: 'Ремкомплект двигателя',
				},
				content: {
					eng: productFGSEng,
					ru: productFGSRu
				},
				icon: 'IconGasketSet',
				img: {
					slider: null,
					modal: null
				}
			},
		]
	},

	mutations: {
		changeLang(state, lang) { 
			state.lang = lang
			Cookies.set('lang', lang)
		},
		changeModalStatus(state, status) { state.modal.status = status },
		changeModalType(state, type) { state.modal.type = type },
		changeModalProduct(state, id) { state.modal.productId = id }
	},

	getters: {
		contactsMain: (state) => ({
			phone: state.contacts.phones.find((el) => el.type === 'main'),
			email: state.contacts.email.find((el) => el.type === 'main')
		}),
		productsList: (state) => state.products.map((x) => ({
			title: x.title[state.lang],
			id: x.id
		}))
	},

	plugins: [
		createLogger()
	]
})
