/* eslint-disable no-eval */

export default `
Тормозные диски AZUMA полностью совместимы с оригинальными тормозными системами, имеют отличный баланс между показателями эффективности и комфортности торможения в обычных условиях эксплуатации как при высоких, так и при малых нагрузках.
Изготавливаются из высокоуглеродистой стали, обеспечивают минимальный тормозной пусть в сочетании с колодками AZUMA.


Особенности и преимущества:
* Контролируемое торможение и высокая чувствительность тормозов;
* Высокая эффективная тормозная динамика;
* Хорошее сопротивление к растрескиванию и деформациям;
* Отсутствие биений и вибрации при торможениях.
`
