<template lang="pug">  
IconBase.icon-close(
    iconName="Close Icon"
    viewBox="0 0 24 24"
)
    path(d='M23.294 2.11763C23.6839 1.72778 23.6839 1.09572 23.294 0.705876C22.9042 0.316031 22.2721 0.316031 21.8823 0.705876L0.706004 21.8821C0.31616 22.272 0.31616 22.904 0.706004 23.2939C1.09585 23.6837 1.72791 23.6837 2.11775 23.2939L23.294 2.11763Z' fill='#8E8E8E')
    path(d='M21.8821 23.2945C22.272 23.6843 22.904 23.6843 23.2939 23.2945C23.6837 22.9046 23.6837 22.2726 23.2939 21.8827L2.11763 0.706451C1.72778 0.316606 1.09572 0.316606 0.705875 0.706451C0.316031 1.0963 0.316031 1.72836 0.705875 2.1182L21.8821 23.2945Z' fill='#8E8E8E')
</template>

<script>
import IconBase from './IconBase.vue'

export default {
	components: {
		IconBase
	},

	props: {
		id: {
			type: String,
			required: true
		}
	}, 
}
</script>

<style>

</style>
