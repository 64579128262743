<template lang="pug">
ul.product-list(ref="elem")
    li.product-list-item(
        v-for="product in products" 
        @click="changeModalStatus(product.id)"
    ) 
        component.product-list-icon(:is="product.icon" :id="`first-${product.icon.toLowerCase()}`")
        span.product-list-text {{ product.title[lang] }}
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
import hammer from '../assets/js/hammer'

import IconAirFilter from './IconAirFilter.vue'
import IconATMFilters from './IconATMFilters.vue'
import IconWiper from './IconWiper.vue'
import IconChainTimingKit from './IconChainTimingKit.vue'
import IconBrakeDisk from './IconBrakeDisk.vue'
import IconBrakeShoes from './IconBrakeShoes.vue'
import IconFunClutch from './IconFunClutch.vue'
import IconGasketSet from './IconGasketSet.vue'

export default {
	components: {
		IconAirFilter,
		IconATMFilters,
		IconWiper,
		IconChainTimingKit,
		IconBrakeDisk,
		IconBrakeShoes,
		IconFunClutch,
		IconGasketSet
	},

	setup() {
		try {
			const store = useStore()
			const elem = ref(null)
			const products = store.state.products.filter((el) => el.layout.main)
			const lang = computed(() => store.state.lang)

			const changeModalStatus = (id) => {
				store.commit('changeModalStatus', true)
				store.commit('changeModalType', 'product')
				store.commit('changeModalProduct', id)
			}

			onMounted(() => {
				hammer(elem.value)
			})

			return {
				products,
				lang,
				elem,
				changeModalStatus
			}
		} catch (err) {
			console.log(err)
			throw err
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/animation.css';

.product-list {
    --product-padding: 40px;
    display: flex;
    padding-left: calc(var(--container-padding) - var(--product-padding));
    padding-right: calc(var(--container-padding) - var(--product-padding));

    &:hover {
        .product-list-item {
            &:not(:hover) {
                opacity: .6 !important;
            }
        }
    }

    &.nopan {
        justify-content: center;

        .product-list-item {
            padding-left: 16px;
            padding-right: 16px;
        }

        .product-list-text {
            white-space: normal;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: var(--product-padding);
        padding-left: var(--product-padding);
        padding-bottom: 48px;
        border-right: 1px solid rgba(255, 255, 255, .1);
        position: relative;
        cursor: pointer;
        opacity: 1;
        transition: all .2s ease-in-out;
        @media (--viewport-xl) {
            width: 160px;
        }

        &:hover {
            transform: scale(1.05) translate3d(0,0,0);
        }

        &:last-of-type {
            border-right: 0;
        }
    }

    &-icon {
        width: 48px;
        height: 48px;
    }

    &-text {
        font-size: 16px;
        font-weight: 700;
        font-family: var(--font-condensed);
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        margin-top: 16px;
        white-space: nowrap;
    }
}
</style>
