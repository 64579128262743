/* eslint-disable no-eval */

export default `
AZUMA wiper blades combine high functionality, modern design, practicality and are able to cope much better with cleaning car windscreens from various pollutants and precipitation.

Features and benefits:
* Increased service life of the structure
* Anti-corrosion coating of the brush frame
* Special coating, ANTI-Static Coating of the blade does not accumulate static charge on the windshield and creates a water-repellent layer with a shield
`
