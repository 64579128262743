<template lang="pug">
#advantages.advantages
    .container
        ul.advantages-list
            li.advantages-list-item(v-for="(item, index) in items")
                component.advantages-list-icon(
                    :is="item.icon"
                    :id="`icon-adv-${index}`"
                )
                .advantages-list-content
                    span.advantages-list-num(v-if="item.num") {{ item.num }}
                    span.advantages-list-text {{ item.text[lang] }}
        .advantages-content
            span.advantages-title {{ title[lang] }}
            ul.advantages-title-list    
                li.advantages-title-item(v-for="item in titleItems[lang]") {{ item }}
            Button(
                color="border" 
                @click="changeModalStatus" 
            ) {{ btnText }}
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import IconAdv0 from './IconAdv0.vue'
import IconAdv1 from './IconAdv1.vue'
import IconAdv2 from './IconAdv2.vue'

import Button from './Button.vue'

export default {
	components: {
		Button,
		IconAdv0,
		IconAdv1,
		IconAdv2
	},

	setup() {
		const store = useStore()
		const lang = computed(() => store.state.lang)
		const title = {
			eng: 'Advantages',
			ru: 'Преимущества'
		}

		const btnText = computed(() => store.state.dataButton[store.state.lang])

		const titleItems = {
			ru: [
				'Прямые поставки с заводов изготовителей под единым брендом',
				'Высочайший уровень контроля качества',
				'Доступные цены',
				'Аккредитованное представительство в России'
			],
			eng: [
				'Direct deliveries from manufacturers under the same brand',
				'The highest level of quality control',
				'Affordable price',
				'Accredited representative office in Russia'
			]
		}
		const items = [
			{
				icon: 'IconAdv0',
				num: 2,
				text: {
					eng: 'Years warranty',
					ru: 'Года гарантии'
				}
			},
			{
				icon: 'IconAdv1',
				num: 2,
				text: {
					eng: 'Times more profitable than analogues',
					ru: 'В 2 раза выгоднее аналогов'
				}
			},
			{
				icon: 'IconAdv2',
				num: null,
				text: {
					eng: 'Large selection products',
					ru: 'Большой выбор продукции'
				}
			}
		]

		const changeModalStatus = () => {
			store.commit('changeModalStatus', true)
			store.commit('changeModalType', 'form')
		}
        
		return {
			title,
			items,
			lang,
			titleItems,
			btnText,
			changeModalStatus
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';

.advantages {
    background: url('../assets/img/background-advantage.jpg') bottom left / auto 200px no-repeat;
    padding-bottom: 80px;
    @media (--viewport-md) {
        background-position: bottom center;
        background-size: 100% auto;
    }

    .button {
        width: 240px;
        height: 48px;
        margin-bottom: 0;
    }

    .container {
        display: flex;
        flex-direction: column;
        padding-top: 56px;
        margin-top: 56px;
        border-top: 1px solid rgba(0,0,0,.1);
        position: relative;
        z-index: 1;
        @media (--viewport-md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        @media (--viewport-lg) {
            margin-top: 64px;
        }
        @media (--viewport-lg) {
            margin-top: 80px;
            padding-top: 80px;
        }
        @media (--viewport-xl) {
            margin-top: 96px;
        }
    }

    &-content {
        order: -1;
        @media (--viewport-lg) {
            order: 1;
            padding-top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &-title {
        display: flex;
        font-family: var(--font-condensed);
        font-weight: 600;
        font-size: 40px;
        line-height: 45px;
        text-transform: uppercase;
        color: #2E2E2E;
        margin-bottom: 24px;
        margin-top: 0;
        @media (--viewport-xl) {
            justify-content: flex-end;
        } 

        &-list {
            margin-top: 0;
            margin-bottom: 32px;
            padding-left: 0;
        }

        &-item {
            list-style-type: none;
            margin-bottom: 8px;
            font-family: var(--font-condensed);
            font-size: 18px;
            line-height: 140%;
            text-transform: uppercase;
            color: #000000;
            @media (--viewport-xl) {
                text-align: right;
            }   
        }
    }

    &-list {
        padding-left: 0;
        margin-bottom: 32px;
        margin-top: 0;
        max-width: 260px;
        margin-top: 64px;
        @media (--viewport-md) {
            margin-top: 0;
        }
        @media (--viewport-lg) {
            max-width: 100%;
            display: flex;
            width: 60%;
        }

        &-item {
            display: flex;
            align-items: center;
            list-style-type: none;
            margin-bottom: 16px;
            @media (--viewport-lg) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            @media (--viewport-xl) {
                margin-right: 64px;
                padding-right: 64px;
                border-right: 1px solid rgba(0,0,0,.1);
            }
        }

        &-content {
            display: flex;
            align-items: center;
            padding-left: 32px;
            justify-self: flex-start;
            width: 70%;
            margin-left: auto;
            @media (--viewport-lg) {
                padding-left: 0;
                margin-top: 24px;
                width: 100%;
                max-width: 160px;
                margin-left: 0;
                margin-right: auto;
            }
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            margin-right: auto;
            max-width: 150px;
        }

        &-icon {
            height: 56px;
            display: flex;
            justify-content: flex-start;
            @media (--viewport-lg) {
                height: 72px;
            }
        }

        &-num {
            font-family: var(--font-condensed);
            font-weight: 600;
            font-size: 70px;
            line-height: 100%;
            color: #000000;
            width: auto;
            margin-right: 16px;
        }
    }
}
</style>
