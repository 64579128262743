/* eslint-disable no-eval */

export default `
The AZUMA transmission filter is available in several types for different types of transmission. Allowing thus to cover the needs of the majority of owners of Japanese cars. Metal mesh filter, designed to capture dirt and deposits of medium size. Felt filter, detains small particles of metal dust. Closed metal filter, has an extended service life. External filter, is easy to change and is made out of felt.


Special Features and benefits:

* produced with proper quality control of raw materials;
* no deformation of the filter due to the heating of a liquid;
* allow the liquid to pass in full volume and under the right pressure;
* the set includes a pallet gasket.
`
