export const dataNavTab = [
	{ 
		url: '#products', 
		name: { 
			ru: 'Продукция',
			eng: 'Products' 
		},
	},
	{ 
		url: '#about', 
		name: { 
			ru: 'Об Azuma',
			eng: 'About' 
		},
	},
	{ 
		url: '#advantages', 
		name: { 
			ru: 'Преимущества',
			eng: 'Advantages' 
		},
	},
	{ 
		url: '#technologies', 
		name: { 
			ru: 'Технологии',
			eng: 'Technologies', 
		}
	}
]

export const dataFirstTitle = {
	title: {
		ru: ['Корейские ', 'Запчасти'],
		eng: ['Korean spare ', 'Parts']
	},
	titleSub: {
		ru: 'для японских автомобилей',
		eng: 'For Japanese cars'
	}
}
