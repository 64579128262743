/* eslint-disable no-eval */

export default `
AZUMA air filters guarantee optimal preparation of the fuel-air mixture, providing high-quality cleaning of the air entering the engine.
Today, the AZUMA range includes more than 200 types of air filters for the most popular cars. The frame of the air filter is made of special polyurethane, which has high elasticity and increased strength (the material does not crack or harden), and is resistant to high and low temperatures. The combination of fibers and special impregnation provides unique properties of the filter material, increasing filtration efficiency.

Special Features and benefits:

* Modern production technology
* Exact fit to the vehicle's installation dimensions
* High thermal stability and reliability
`
