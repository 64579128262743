/* eslint-disable no-eval */

export default `
Фильтра трансмиссии AZUMA выпускается нескольких видов для разных типов трансмиссии. Позволяя тем самым перекрыть потребности большинства владельцев японских машин. Сетчатый металлический фильтр, предназначен для улавливания грязи и отложений средних размеров. Фетровый фильтр, задерживает мелкие частицы металлической пыли. Закрытый металлический фильтр, имеет повышенный срок службы. Внешний фильтр, легко меняется и производится из фетры.


Особенности и преимущества:
* производятся при должном контроле качества сырья;
* исключена деформация фильтра из-за нагрева жидкости;
* позволяют жидкости проходить в полном объеме и под нужным давлением;
* в комплекте имеется прокладка поддона.
`
