<template lang="pug">
section#products.products(
    ref="el"
    :class="className"
)
    Slider(
        layout="center"
        :activeSlideInit="slideCurrentInit"
        :slideGroup="slideGroup"
    )
        .slider-item(v-for="(product, index) in products")
            Product(
                :data="product"
                :index="index"
            )
</template>

<script>
import scrollMonitor from 'scrollmonitor'
import { useStore } from 'vuex'
import { ref, onMounted, computed } from 'vue'
import Slider from './Slider.vue'
import Product from './Product.vue'

export default {
	components: {
		Product,
		Slider
	},

	setup() {
		const store = useStore()
		const products = ref(store.state.products.filter((el) => el.layout.slider))
		const slideCurrentInit = Math.ceil(products.value.length / 2)
		const slideGroup = ref(1)
		const el = ref(null)
		const show = ref(false)

		const getSliderGroup = () => {
			const windowWidth = window.innerWidth
			if (windowWidth < 768) {
				slideGroup.value = 1
			} else if (windowWidth >= 768 && windowWidth < 1024) {
				slideGroup.value = 3
			} else if (windowWidth >= 1024 && windowWidth < 1200) {
				slideGroup.value = 4
			} else if (windowWidth >= 1200) {
				slideGroup.value = 4
			} 
		}

		const className = computed(() => (show.value ? 'show' : false))

		onMounted(() => {
			getSliderGroup()
			window.addEventListener('resize', () => {
				getSliderGroup()
			})

			const watcher = scrollMonitor.create(el.value)
			watcher.enterViewport(() => {
				show.value = true
			})
		})

		return {
			products,
			slideCurrentInit,
			slideGroup,
			show,
			getSliderGroup,
			className,
			el
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/animation.css';

.products {
    position: relative;
    padding-top: 16px;
    width: 100%;
    overflow: hidden;
    opacity: 0;

    &.show {
        animation: showDownTop .7s .3s ease-in-out forwards;   
    }

    &:hover {
        .product {
            &:not(:hover) {
                box-shadow: rgba(0, 0, 0, 0.02) 0px -10px 30px !important;
            }

            &:hover {
                z-index: 5;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        background: #F7F7F7;
        top: 0;
        left: 0;
        width: 100%;
        height: 290px;
    }

    .slider-item {
        flex-shrink: 0;
        flex-basis: calc(240px + 16px);
        padding-left: 8px;
        padding-right: 8px;
    }

    .product {        
        &.top {
            margin-top: 24px;
        }
    }
}
</style>
