<template lang="pug">
header.header(ref="header" :class="className")
    .container
        .header-contact
            Contact(
                v-for="contact in contacts"
                :link="contact.link"
                :num="contact.name"
            )
        Logo(id="header-logotype")
        NavTab
        .header-button
            Button(
                @click="changeModalStatus" 
                color="transparent"
            ) {{ btnText }}
</template>

<script>
import { useStore } from 'vuex'
import { ref, onMounted, computed } from 'vue'
import scrollMonitor from 'scrollmonitor'

import Button from './Button.vue'
import Contact from './Contacts.vue'
import Logo from './IconLogotype.vue'
import NavTab from './TheHeaderNav.vue'

export default {
	components: {
		Button,
		Contact,
		Logo,
		NavTab
	},
    
	setup() {
		try {
			const store = useStore()
			const contacts = store.getters.contactsMain
			const className = ref([])
			const header = ref(null)
			const changeModalStatus = () => {
				store.commit('changeModalStatus', true)
				store.commit('changeModalType', 'form')
			}
			const btnText = computed(() => store.state.dataButton[store.state.lang])

			onMounted(() => {
				const watcher = scrollMonitor.create(header.value)
				watcher.enterViewport(() => {
					if (!className.value.includes('show')) {
						className.value.push('show')
					}
				})
			})
            
			return {
				contacts,
				btnText,
				header,
				className,
				changeModalStatus
			}
		} catch (err) {
			console.log(err)
			throw err
		}
	}
}
</script>

<style lang="postcss" scoped>
@import '../assets/css/media.css';
@import '../assets/css/animation.css';

.header {
    display: flex;
    width: 100%;
    overflow: hidden;
    padding-top: 32px;
    @media (--viewport-md) {
        height: 131px;
    }
    @media (--viewport-lg) {
        height: auto;
        padding-top: 8px;
    }

    &.show {
        .logotype {
            animation: showLeftRight .7s .2s ease-in-out both;
            @media (--viewport-sm) {
                animation: showDownTop .7s .2s ease-in-out both;
            }
        }
        .contact {
            &-mailto {
                animation: showLeftRight .7s .4s ease-in-out both;
                @media (--viewport-sm) {
                    animation: showDownTop .7s .4s ease-in-out both;
                }
            }
            &-tel {
                animation: showLeftRight .7s .3s ease-in-out both;
                @media (--viewport-sm) {
                    animation: showDownTop .7s .3s ease-in-out both;
                }
            }
        }
        ::v-deep(.header-nav-item) {
            @for $count from 1 to 8 {
                &:nth-child($count) {
                    animation: showLeftRight .7s calc(.2s + 0.05s * $count) ease-in-out both;
                    @media (--viewport-sm) {
                        animation: showDownTop .7s calc(.2s + 0.05s * $count) ease-in-out both;
                    }
                }
            }
        }

        .button {
            @media (--viewport-sm) {
                animation: showDownTop .7s .3s ease-in-out both;
            }
        }
    }

    .container {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        position: relative;
        transition: all .3s ease-in-out;
        @media (--viewport-md-max) {
            width: 100%;
            max-width: 100%;
        }
        @media (--viewport-md) {
            flex-flow: column wrap;
            border-bottom: var(--header-nav-link); 
        }
        @media (--viewport-lg) {
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    .logotype {
        margin-left: var(--container-padding);
        order: -1;
        width: 80px;
        @media (--viewport-sm) {
            margin-left: auto;
            margin-right: auto;
        }
        @media (--viewport-md) {
            order: 0;
            margin-left: 0;
            margin-right: auto;
        }
        @media (--viewport-lg) {
            display: none;
        }
    }

    .button {
        @media (--viewport-sm) {
            display: flex;
            min-width: 148px;
        }
        @media (--viewport-lg) {
            margin-left: auto;
            min-width: 160px;
            height: 36px;
        }
    }

    .header-nav {
        display: flex;
        margin-top: 24px;
        @media (--viewport-md) {
            order: 1;
            margin-top: 0;
            margin-right: auto;
        }
        @media (--viewport-lg) {
            margin-left: auto;
            margin-right: auto;
            padding-left: 32px;
            padding-right: 32px;
            flex-grow: 2;
        }
    }

    &-button {
        display: none;
        @media (--viewport-sm) {
            display: flex;
            margin-right: var(--container-padding);
        }
        @media (--viewport-sm-md) {
            order: -1;
        }
        @media (--viewport-md) {
            order: 2;
            margin-left: auto;
            margin-right: 0;
            margin-bottom: 16px;
        }
        @media (--viewport-lg) {
            margin-bottom: 0;
            flex-grow: 1;
        }
    }

    &-contact {
        display: flex;
        margin-left: auto;
        margin-right: var(--container-padding);
        @media (--viewport-sm-md) {
            order: -1;
        }
        @media (--viewport-sm) {
            flex-direction: column;
            margin-left: var(--container-padding);
            margin-right: 0;
        }
        @media (--viewport-md) {
            order: 3;
            margin-left: auto;
            margin-right: 0;
        }
        @media (--viewport-lg) {
            order: 0;
            margin-right: auto;
            margin-left: 0;
            flex-grow: 1;
        }
        
        .contact {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            width: 40px;
            height: 40px;
            padding: 6px;
            @media (--viewport-sm) {
                width: auto;
                height: auto;
                padding: 0;
            }

            &-mailto { 
                order: -1; 
                @media (--viewport-sm) {
                    order: 1;
                }
                @media (--viewport-md) {
                    display: none;
                }
                @media (--viewport-lg) {
                    display: flex;
                }
            }

            &-tel { 
                margin-right: 0; 
                @media (--viewport-sm) {
                    margin-bottom: 4px;
                }
                @media (--viewport-lg) {
                    margin-bottom: 2px;
                }
            }
        }

        ::v-deep(.contact) {
            &-num { 
                display: none; 
                @media (--viewport-sm) {
                    display: block;
                }
            }

            &-text { 
                display: none; 
            }

            &-icon {
                width: 27px;
                height: 22px;
                @media (--viewport-sm) {
                    display: none;
                }
            }
        }
    }

}
</style>
