/* eslint-disable no-eval */

export default `
Цепные наборы AZUMA позволяют наиболее оптимально работать группе клапанов и поршней, обеспечивая их согласованную работу. Цепь ГРМ объединяет колевал с распредвалом в слаженный механизм. Всё это создается благ даря точно выверенным деталям набора. Шестерни сделаны из металла качественной закалки. Натяжитель цепи имеет силиконовые прокладки у штоков. Башмаки сделаны из МБС пластика и алюминия, что облегчает конструкцию

Особенности и преимущества:

* исключительное качество;
* рассчитан на пробег более 200 тыс. км при должном регламенте масла;
* цепь устойчива к локальным перегрузкам двигателя;
* детали выполнены из легких, но прочных материалов.
`
