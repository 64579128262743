/* eslint-disable no-eval */

export default `
AZUMA oil filters clean the engine oil from solid organic and inorganic particles to perfection, foreign impurities and combustion products, providing reliable protection of automobile engines.                 

Special molding and deep corrugation of the filter material provides a large filtration area and eliminates the penetration of crude oil to the engine parts.


Features and benefits:

* High strength of housing elements
* Double-sided anti-corrosion coating
* High temperature resistance
`
